import React from 'react'
import RawMaterialsList from "../components/RawMaterialsList";

const VetOfficeRawMaterials = () => {
  return (
    <div>
      <RawMaterialsList />
    </div>
  );
}

export default VetOfficeRawMaterials;
