import React, { useEffect, useReducer } from "react";
import Form from "react-bootstrap/Form";

const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: true
      }
    default:
      return state;
  }
};


const Checkbox = (props) => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.initialValue,
    isValid: props.initialValid || false
  });

  const { id, onInput } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  const changeHandler = (event) => {
    let val;
    if (props.inverse) {
      val = !event.target.checked
    } else {
      val = event.target.checked
    }

    dispatch({
      type: "CHANGE",
      val: val
    });
    if (props.onSelect) props.onSelect(event);
  };

  useEffect(() => {
    if (props.initialValue && !props.inverse) {
      document.getElementById(props.id).checked = true;
    }
  }, [props.id, props.initialValue, props.inverse])

  useEffect(() => {
    if (props.reset) {
      dispatch({
        type: "RESET",
        val: props.resetValue,
        isValid: props.resetValid || false,
      });
      if (props.inverse) {
        document.getElementById(props.id).checked = !props.resetValue;
      } else {
        document.getElementById(props.id).checked = props.resetValue;
      }
    }
  }, [props.reset, props.id, props.resetValid, props.resetValue, props.inverse]);

  return (
    <Form.Check
      custom
      data-testid="checkbox"
      type={props.type}
      id={props.id}
      label={props.label}
      onChange={changeHandler}
    />
  );
};

export default Checkbox;
