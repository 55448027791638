import React from "react";

import SupplierTabs from "../components/SupplierTabs";
import SupplierList from "../components/SupplierList";

const Supplier = () => {
  return (
    <div>
      <SupplierTabs isIndex />
      <p className="h2">Übersicht Lieferanten</p>
      <SupplierList />
    </div>
  );
};

export default Supplier;
