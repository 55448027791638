import React from "react";

const Shipments = () => {
  return (
    <div>
      <h1>Shipments</h1>
    </div>
  );
};

export default Shipments;
