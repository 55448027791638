import React, { useState, useEffect } from 'react';
import moment from "moment";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import CleaningStepDashCardActions from './CleaningStepDashCardActions'
import { useReminder } from "../../hooks/reminder-hook";

const CleaningStepDashCard = (props) => {
  const { getDueDate, getReminder } = useReminder();
  const [borderClass, setBorderClass] = useState();
  const cs = props.cleaningStep;

  let dueDate;
  let reminder;
  if (cs.lastCheckedAt) {
    dueDate = moment(getDueDate(cs.lastCheckedAt, cs.repeat)).format("DD-MM-yyyy");
    if (getDueDate(cs.lastCheckedAt, cs.repeat) === "Manuell") {
      dueDate = "Manuell"
    }
    reminder = getReminder(cs.lastCheckedAt, dueDate);
  } else {
    dueDate = moment(getDueDate(new Date(), cs.repeat)).format(
      "DD-MM-yyyy"
    );
    reminder = getReminder(undefined, dueDate);
  }

useEffect(() => {
  console.log(cs.lastCheckedAt)
  // console.log(reminder);
  switch (reminder) {
    case "overdue":
       setBorderClass("border-danger")
      break;
    case "today":
       setBorderClass("border-warning")
      break;
    case "checked":
       setBorderClass("border-success")
      break;
    case "Manuell":
       setBorderClass("border-primary")
      break;
    case "default":
      setBorderClass("border-secondary")
      break;
    default:
       setBorderClass("border-secondary")
      break;
  }
}, [reminder, cs])



  return (
    <Card className={`p-1 m-3 border ${borderClass}`}>
      <Card.Body>
        <Row className="text-center">
          <Col>{cs.area}</Col>
          <Col>{cs.kind}</Col>
          <Col>{cs.cleaningAgent}</Col>
          <Col xs={3}>
            {reminder === "today" ? (
              <strong>heute</strong>
            ) : (
              dueDate
            )}
          </Col>
          <Col xs={1}>
            <CleaningStepDashCardActions
              cleaningCheckCallback={props.cleaningCheckCallback}
              cleaningStep={cs}
              cleaningStepId={cs.id}
              roomId={props.roomId}
              dueDate={dueDate}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default CleaningStepDashCard;
