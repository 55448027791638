import React, { useContext } from "react";

import ErrorModal from "../../shared/components/uiElements/ErrorModal";
import Input from "../../shared/components/formElements/Input";
import SuccessButton from "../../shared/components/formElements/SuccessButton";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { VALIDATOR_REQUIRE } from "../../shared/util/validators";
import { useSuccess } from "../../shared/hooks/success-hook";
import { AuthContext } from "../../shared/context/auth-context";

const UpdateInfo = (props) => {
  const auth = useContext(AuthContext);
  const { loading, success, onStart, onSuccess, onError } = useSuccess();
  const { error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm(
    {
      firstName: {
        value: props.userInfo.firstName,
        isValid: false,
      },
      lastName: {
        value: props.userInfo.lastName,
        isValid: false,
      },
      city: {
        value: props.userInfo.city,
        isValid: false,
      },
      postalCode: {
        value: props.userInfo.postalCode,
        isValid: false,
      },
      addrLine: {
        value: props.userInfo.addrLine,
        isValid: false,
      },
    },
    false
  );


  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      onStart();
      await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/users/updateprofile",
          "POST",
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
          JSON.stringify({
            firstName: formState.inputs.firstName.value,
            lastName: formState.inputs.lastName.value,
            postalCode: formState.inputs.postalCode.value,
            city: formState.inputs.city.value,
            addrLine: formState.inputs.addrLine.value,
        })
      )
      onSuccess();
    } catch (error) {
      onError();
    }
  };

  return (
    <div>
      <ErrorModal error={error} onClear={clearError} />
      <form onSubmit={submitHandler}>
        <div className="row">
          <Input
            classes="bg-light"
            col="col-6"
            id="firstName"
            label="Vorname"
            element="input"
            type="text"
            onInput={inputHandler}
            validators={[VALIDATOR_REQUIRE()]}
            initialValue={props.userInfo.firstName}
            initialValid={true}
          />
          <Input
            classes="bg-light"
            col="col-6"
            id="lastName"
            label="Nachname"
            element="input"
            type="text"
            onInput={inputHandler}
            validators={[VALIDATOR_REQUIRE()]}
            initialValue={props.userInfo.lastName}
            initialValid={true}
          />
        </div>
        <div className="row">
          <Input
            classes="bg-light"
            col="col-6"
            id="postalCode"
            label="Postleitzahl"
            element="input"
            type="text"
            onInput={inputHandler}
            validators={[VALIDATOR_REQUIRE()]}
            initialValue={props.userInfo.postalCode}
            initialValid={true}
          />
          <Input
            classes="bg-light"
            col="col-6"
            id="city"
            label="Stadt"
            element="input"
            type="text"
            onInput={inputHandler}
            validators={[VALIDATOR_REQUIRE()]}
            initialValue={props.userInfo.city}
            initialValid={true}
          />
        </div>
        <Input
          classes="bg-light"
          id="addrLine"
          label="Strasse, Hausnummer"
          element="input"
          type="text"
          onInput={inputHandler}
          validators={[VALIDATOR_REQUIRE()]}
          initialValue={props.userInfo.addrLine}
            initialValid={true}
        />
        <br />
        <SuccessButton width="200px" isLoading={loading} success={success}>
          Speichern
        </SuccessButton>
      </form>
    </div>
  );
};

export default UpdateInfo;
