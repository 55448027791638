import React from "react";

const Pagination = ({
  itemsPerPage,
  totalItems,
  paginate,
  changeItemsPerPage,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        <select
          onChange={(event) => changeItemsPerPage(event.target.value)}
          name="iPerP"
          id="iPerP"
          className="page-link"
          defaultValue="10"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
        </select>
        {pageNumbers.map((number) => (
          <li key={number} className="page-item">
            <button onClick={(event) => paginate(number)} className="page-link">
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
