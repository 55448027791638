import { useReducer } from "react";
import moment from "moment";

const level1Reducer = (state, action) => {
  switch (action.type) {
    case "date":
      const res = action.value.filter((result) => {
        return (
          new Date(result.createdAt) >= action.start &&
          new Date(result.createdAt) <= moment(action.end).add(1, "days")
        );
      });
      return res;
    default:
      return state;
  }
};

const level2Reducer = (state, action) => {
  switch (action.type) {
    case "approved":
      return action.value.filter((result) => result.approved === true);
    case "pending":
      return action.value.filter((result) => result.testedAt === undefined && result.vetId);
    case "denied":
      return action.value.filter((result) => result.approved === false);
    case "all":
      return action.value;
    case "notspecified":
      return action.value.filter((result) => result.vetId === undefined);
    case "novet":
      return action.value.filter((result) => result.vetId === null);
    case "query":
      const res = action.value.filter((result) => {
        return (
          result.type.toLowerCase().includes(action.query.toLowerCase()) ||
          result.supplierName
            .toLowerCase()
            .includes(action.query.toLowerCase()) ||
          result.hunting_district
            .toLowerCase()
            .includes(action.query.toLowerCase())
        );
      });
      return res;
    default:
      return state;
  }
}

const level3Reducer = (state, action) => {
  switch (action.type) {
    case "query":
      const res = action.value.filter((result) => {
        return (
          result.type.toLowerCase().includes(action.query.toLowerCase()) ||
          result.supplierName
            .toLowerCase()
            .includes(action.query.toLowerCase()) ||
          result.hunting_district
            .toLowerCase()
            .includes(action.query.toLowerCase())
        );
      });
      return res;
    default:
      return state;
  }
};

export const useFilter = () => {
  const [level1results, dispatchLevel1] = useReducer(level1Reducer, []);
  const [level2results, dispatchLevel2] = useReducer(level2Reducer, []);
  const [filteredResults, dispatchLevel3] = useReducer(level3Reducer, []);

  const dateFilterHandler = (dateRange, toFilterValues) => {
    dispatchLevel1({
      type: "date",
      value: toFilterValues,
      start: dateRange.start,
      end: dateRange.end
    })
  }

  const filterHandler = (filterValue, toFilterValues) => {
    dispatchLevel2({
      type: filterValue,
      value: toFilterValues
    })
  }

  const queryHandler = (query, toFilterValues) => {
        dispatchLevel3({
          type: "query",
          value: toFilterValues,
          query: query
        })
  }


  return { filteredResults, level1results, level2results, dateFilterHandler, filterHandler, queryHandler };
};
