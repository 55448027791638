import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

import ErrorModal from '../../shared/components/uiElements/ErrorModal';
import Select from '../../shared/components/formElements/Select';
import SuccessButton from '../../shared/components/formElements/SuccessButton';
import AddRoom from "../components/roomIndex/AddRoom";
import DeleteRoom from "../components/roomIndex/DeleteRoom";
import FileUpload from '../../shared/components/formElements/FileUpload';
import RoomsListTabs from '../components/roomIndex/RoomsListTabs';
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useForm } from "../../shared/hooks/form-hook";

import "../../css/SettingsBtn.css";

const RoomsSettings = (props) => {
  const auth = useContext(AuthContext);
    const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [roomsRes, setRoomsRes] = useState();
  const [coldRooms, setColdRooms] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [formState, inputHandler] = useForm(
    {
      csv: {
        value: null,
        isValid: false,
      },
    },
    false
  );

  const submitHandler = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", formState.inputs.csv.value);
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/rooms/tempdata",
        "POST",
        {
          Authorization: "Bearer " + auth.token,
        },
        formData
      );
    } catch (error) {
      // handled by useHttpClient hook
    }
  };

    useEffect(() => {
      const fetchRooms = async () => {
        try {
          const res = await sendRequest(
            process.env.REACT_APP_BACKEND_URL + "/rooms",
            "GET",
            { Authorization: "Bearer " + auth.token }
          );
          setRoomsRes(res.rooms);
          setColdRooms(res.rooms.filter((room) => room.cold));
        } catch (error) {}
      };
      fetchRooms();
    }, [auth.token, sendRequest]);

  let options;
  if (roomsRes) {
    options = roomsRes.map((room) => ({
      key: room.name,
      value: room.id,
    }));
  }

  const redirectHandler = (event) => {
    event.preventDefault();
    const roomId = event.target.querySelector("#goToRoom").value;
    history.push(`/${roomId}/hygene/settings`);
  }


  const addCallback = (inputs) => {
    let updatedRooms = Object.assign([], roomsRes);
    updatedRooms.push({
      name: inputs.roomName.value,
      cold: inputs.coldRoom.value,
    });
    setRoomsRes(updatedRooms);
  };

  const deleteCallback = (id) => {
    let updatedRooms = Object.assign([], roomsRes);
    const index = roomsRes.findIndex((element) => element.id === id);
    updatedRooms.splice(index, 1);
    setRoomsRes(updatedRooms);
  };

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <RoomsListTabs isSettings />
      {isLoading && (
        <div className="d-flex justify-content-center loading-screen">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      {roomsRes && coldRooms && (
        <>
          <div
            className={`settings-section bg-white rounded-lg p-5 ${
              showDelete ? "border border-danger" : ""
            }`}
          >
            <p className="h4">Räume verwalten</p>
            <p>
              {roomsRes.length} Räume{" "}
              <i
                className="far fa-snowflake text-primary"
                style={{ fontSize: "1rem" }}
              ></i>
              {"  "}
              {coldRooms.length}{" "}
              <i
                className="fas fa-circle text-secondary"
                style={{ fontSize: "1rem" }}
              ></i>
              {"  "}
              {roomsRes.length - coldRooms.length}
            </p>
            <hr className="mb-4" />
            {!showDelete && <AddRoom addCallback={addCallback} />}
            {showDelete && (
              <DeleteRoom rooms={roomsRes} deleteCallback={deleteCallback} />
            )}
            <hr className="mt-4 mb-1" />
            <button
              className="stealth-button text-primary"
              onClick={() => setShowDelete((prevMode) => !prevMode)}
            >
              {showDelete ? "zurück" : "Raum entfernen"}
            </button>
          </div>

          <div className={`settings-section bg-white rounded-lg p-5 mt-5`}>
            <p className="h4">Reinigungsschritte verwalten</p>
            <p className="w-75">
              Die Verwaltung von Reinigungsschritten kann in den Einstellungen
              des jeweiligen Raums vorgenommen werde.
            </p>
            <hr className="mb-4" />
            <form onSubmit={redirectHandler}>
              <div className="row justify-content-start align-items-center">
                <Select
                  id="goToRoom"
                  classes="bg-light"
                  col="col-7"
                  element="input"
                  type="text"
                  validators={[]}
                  options={options}
                  onInput={() => {}}
                />
                <SuccessButton primary width="150px" classes="ml-2 rsp-btn">
                  Zum Raum
                </SuccessButton>
              </div>
            </form>
            <hr className="mt-4 mb-1" />
          </div>
          <div className={`settings-section bg-white rounded-lg p-5 mt-5`}>
            <p className="h4">Kühlung verwalten</p>
            <p className="w-75">
              Hier können Sie Aufzeichnungen von Temperaturfühlern im CSV format
              hochladen.
            </p>
            <hr className="mb-4" />
            <form onSubmit={submitHandler}>
              <div className="d-flex">
                <div className="col-7">
                  <FileUpload id="csv" onInput={inputHandler} />
                </div>
                <div style={{ height: "40px" }}>
                  <SuccessButton classes="ml-3" primary width="150px">
                    Hochladen
                  </SuccessButton>
                </div>
              </div>
            </form>
            <hr className="mt-4 mb-1" />
          </div>
          <br />
        </>
      )}
    </>
  );
};

export default RoomsSettings;
