import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import Input from "../../shared/components/formElements/Input";
import Button from "../../shared/components/formElements/Button";
import SuccessButton from "../../shared/components/formElements/SuccessButton";
import ErrorModal from "../../shared/components/uiElements/ErrorModal";
import { useForm } from "../../shared/hooks/form-hook";
import { useSuccess } from "../../shared/hooks/success-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../shared/util/validators";

const Auth = () => {
  const auth = useContext(AuthContext);
  const { loading, success, onStart, onSuccess, onError } = useSuccess();
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const { error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler, setFormData] = useForm({
    email: {
      value: "",
      isValid: false,
    },
    password: {
      value: "",
      isValid: false,
    },
  });

  const submitHandler = async (event) => {
    event.preventDefault();
    if (isLoginMode) {
      try {
        onStart();
        const res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/users/login",
          "POST",
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
          JSON.stringify({
            email: formState.inputs.email.value,
            password: formState.inputs.password.value,
          })
        );
        onSuccess();
        auth.login(res.userId, res.userRole, res.token);
      } catch (error) {
        onError();
        // handled by useHttpClient hook
      }
    } else {
      try {
        onStart();
        const res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/users/signup",
          "POST",
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
          JSON.stringify({
            email: formState.inputs.email.value,
            password: formState.inputs.password.value,
            companyName: formState.inputs.companyName.value,
            addr_line: formState.inputs.addr_line.value,
            postal_code: formState.inputs.postal_code.value,
            city: formState.inputs.city.value,
            tel: formState.inputs.tel.value,
          })
        );
        onSuccess();
        auth.login(res.userId, res.token);
      } catch (error) {
        onError();
        // handled by useHttpClient hook
      }
    }
  };

  const switchModeHandler = () => {
    if (!isLoginMode) {
      setFormData(
        {
          ...formState.inputs,
          companyName: undefined,
          addr_line: undefined,
          postal_code: undefined,
          city: undefined,
          tel: undefined,
        },
        formState.inputs.email.isValid && formState.inputs.password.isValid
      );
    } else {
      setFormData(
        {
          ...formState.inputs,
          companyName: {
            value: "",
            isValid: false,
          },
          addr_line: {
            value: "",
            isValid: false,
          },
          postal_code: {
            value: "",
            isValid: false,
          },
          city: {
            value: "",
            isValid: false,
          },
          tel: {
            value: "",
            isValid: false,
          },
        },
        false
      );
    }
    setIsLoginMode((prevMode) => !prevMode);
  };

  return (
    <React.Fragment>
      <div className="vh-100">
        <ErrorModal error={error} onClear={clearError} />
        <form action="" onSubmit={submitHandler}>
          {!isLoginMode && (
            <React.Fragment>
              <Input
                id="companyName"
                element="input"
                type="text"
                label="Firmenname"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Bitte geben Sie einen Firmennamen ein."
              />
              <Input
                id="addr_line"
                element="input"
                type="text"
                label="Strasse, HNr"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Bitte geben Sie eine Strasse und Hausnummer ein."
              />
              <Input
                id="postal_code"
                element="input"
                type="text"
                label="Postleitzahl"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Bitte geben Sie eine Postleitzahl ein."
              />
              <Input
                id="city"
                element="input"
                type="text"
                label="Stadt"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Bitte geben Sie eine Stadt ein."
              />
              <Input
                id="tel"
                element="input"
                type="text"
                label="Telefon"
                onInput={inputHandler}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Bitte geben Sie eine Telefonnummer ein."
              />
            </React.Fragment>
          )}
          <Input
            id="email"
            element="input"
            type="text"
            label="Email"
            validators={[VALIDATOR_EMAIL()]}
            onInput={inputHandler}
            errorText="Bitte geben Sie eine gültige Email-Adresse ein."
          />
          <Input
            id="password"
            element="input"
            type={showPassword ? "text" : "password"}
            label="Passwort"
            validators={[VALIDATOR_MINLENGTH(6)]}
            onInput={inputHandler}
          />
          <div className="mt-2">
            <input id="showPw" type="checkbox" className="mr-2" onChange={() => setShowPassword((prevMode) => !prevMode)}/>
            <label htmlFor="showPw">Passwort anzeigen</label>
          </div>
          <SuccessButton
            classes="mt-3"
            isLoading={loading}
            success={success}
            disabled={!formState.isValid}
          >
            {isLoginMode ? "login" : "signup"}
          </SuccessButton>
        </form>
        <div className="mt-3">
          <Button classes="btn-block" outline onClick={switchModeHandler}>
            switch to {isLoginMode ? "signup" : "login"}
          </Button>
        </div>
        {isLoginMode && (
          <div className="mt-3">
            <Link to="/forgotpassword">Passwort vergessen?</Link>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Auth;
