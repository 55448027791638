import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <div className="mt-5">
        <Link to="dashboard/dashboard">
          <Button variant="info" size="lg" block>
            Dashboard
          </Button>
        </Link>
      </div>
      <hr />
      <div className="mt-5 d-flex">
        <div className="w-100">
          <Link to="/supplier/supplier">
            <Button variant="primary" size="lg" block>
              Lieferanten
            </Button>
          </Link>
        </div>
        <div className="ml-2">
          <Link to="supplier/suppliernew">
            <Button variant="primary" size="lg">
              +
            </Button>
          </Link>
        </div>
      </div>
      <div className="mt-5 d-flex">
        <div className="w-100">
          <Link to="/raw_materials/rawmaterials">
            <Button variant="primary" size="lg" block>
              Lieferungen
            </Button>
          </Link>
        </div>
        <div className="ml-2">
          <Link to="/raw_materials/rawmaterialsnew">
            <Button variant="primary" size="lg">
              +
            </Button>
          </Link>
        </div>
      </div>
      <div className="mt-5">
        <Link to="/stock">
          <Button variant="primary" size="lg" block>
            Warenbestand
          </Button>
        </Link>
      </div>
      <hr />
      <div className="mt-5 d-flex">
        <div className="w-100">
          <Link to="/customers/customers">
            <Button variant="secondary" size="lg" block>
              Kunden
            </Button>
          </Link>
        </div>
        <div className="ml-2">
          <Link to="customers/customernew">
            <Button variant="secondary" size="lg">
              +
            </Button>
          </Link>
        </div>
      </div>
      <div className="mt-5 d-flex">
        <div className="w-100">
          <Link to="/order/orders">
            <Button variant="secondary" size="lg" block>
              Bestellungen
            </Button>
          </Link>
        </div>
        <div className="ml-2">
          <Link to="/order/ordernew">
            <Button variant="secondary" size="lg">
              +
            </Button>
          </Link>
        </div>
      </div>
      <div className="mt-5">
        <Link to="/shipments">
          <Button variant="secondary" size="lg" block>
            Sendungen
          </Button>
        </Link>
      </div>
      <hr />
      <div className="mt-5">
        <Link to="/rooms/cooling">
          <Button variant="warning" size="lg" block>
            Raumüberwachung
          </Button>
        </Link>
      </div>
      <div className="mt-5">
        <Link to="/analysis">
          <Button variant="warning" size="lg" block>
            Analysen
          </Button>
        </Link>
      </div>
      <hr />
    </div>
  );
};

export default Home;
