import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const RawMaterialHeaderCard = () => {
  return(
    <Card className="mb-3">
      <Card.Header>
        <Row>
          <Col>
            <strong>Wildart</strong>
          </Col>
          <Col>
            <strong>Lieferant</strong>
          </Col>
          <Col>
            <strong>Revier</strong>
          </Col>
          <Col>
            <div className="test-status">
              <strong>Teststatus</strong>
            </div>
          </Col>
          <Col>
            <strong>Datum</strong>
          </Col>
          <p className="mr-2">
            <strong>PDF</strong>
          </p>
        </Row>
      </Card.Header>
    </Card>
  )
};

export default RawMaterialHeaderCard;
