import React, { useState } from "react";

import ErrorModal from "../../shared/components/uiElements/ErrorModal";
import Modal from "../../shared/components/uiElements/Modal";
import Button from "../../shared/components/formElements/Button";
import Input from "../../shared/components/formElements/Input";
import Select from "../../shared/components/formElements/Select";
import { useUserActions } from "../hooks/user-actions-hook";
import { useForm } from "../../shared/hooks/form-hook";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
} from "../../shared/util/validators";

const UserCardActions = (props) => {
  const { id, email, role, firstName, lastName } = props.userData;
  const {
    deleteHandler,
    resendHandler,
    editHandler,
    error,
    clearError,
  } = useUserActions();
  const [showDelete, setShowDelete] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [formState, inputHandler] = useForm(
    {
      role: {
        value: role,
        isValid: true,
      },
      firstName: {
        value: firstName,
        isValid: true,
      },
      lastName: {
        value: lastName,
        isValid: true,
      },
      email: {
        value: email,
        isValid: true,
      },
    },
    true
  );

  const dispatchActions = async (id, action) => {
    switch (action.type) {
      case "delete":
        setShowDelete(false);
        await deleteHandler(id, () =>
          props.userActionsCallback("delete", {}, id)
        );
        break;
      case "resend":
        setShowResend(false);
        await resendHandler(id, () =>
          props.userActionsCallback("resend", {}, id)
        );
        break;
      default:
        break;
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    setShowEdit(false);
    await editHandler(formState.inputs, id, () =>
      props.userActionsCallback("edit", formState.inputs, id)
    );
  };

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      {role !== "vetOfficial" &&
      <button className="stealth-button" onClick={() => setShowEdit(true)}>
        <i className="fas fa-pen m-3"></i>
      </button>}
      {role !== "owner" && (
        <button className="stealth-button" onClick={() => setShowDelete(true)}>
          <i className="fas fa-trash m-3"></i>
        </button>
      )}
      {props.currentUserStatus === "expired" && (
        <button className="stealth-button" onClick={() => setShowResend(true)}>
          <i className="fas fa-paper-plane m-3"></i>
        </button>
      )}
      <Modal
        onCancel={() => setShowDelete(false)}
        header="Sind Sie sicher?"
        show={showDelete}
        footer={
          <>
            <Button
              classes="btn btn-outline-danger mx-2"
              onClick={() => setShowDelete(false)}
            >
              Abbrechen
            </Button>
            <Button
              classes="btn btn-danger mx-2"
              onClick={() => dispatchActions(id, { type: "delete" })}
            >
              Nutzer löschen
            </Button>
          </>
        }
      >
        <p>Diese Aktion kann nicht rückgängig gemacht werden.</p>
      </Modal>

      <Modal
        onCancel={() => setShowResend(false)}
        header="Neue Einladung"
        show={showResend}
        footer={
          <>
            <Button classes="mx-2" onClick={() => setShowResend(false)} outline>
              Abbrechen
            </Button>
            <Button
              classes="mx-2"
              onClick={() => dispatchActions(id, { type: "resend" })}
              primary
            >
              Abschicken
            </Button>
          </>
        }
      >
        <p>
          Wollen Sie eine neue eine neue Einladung <br />
          an <strong>{email}</strong> verschicken?
        </p>
      </Modal>

      <Modal
        onSubmit={submitHandler}
        initialValue={firstName}
        initialValid={true}
        onCancel={() => setShowEdit(false)}
        header="Nutzerrechte ändern"
        show={showEdit}
        footer={
          <>
            <Button classes="mx-2" onClick={() => setShowEdit(false)} outline>
              Abbrechen
            </Button>
            <Button
              form="modal-form"
              type="submit"
              classes="mx-2"
              onClick={() => {}}
              primary
            >
              Speichern
            </Button>
          </>
        }
      >
        <div className="px-5">
          <br/>
          <Select
            id="role"
            validators={[]}
            onInput={inputHandler}
            options={[
              { key: "ADMIN", value: "admin" },
              { key: "BASIC", value: "basic" },
            ]}
            initialValue={{ key: role.toUpperCase(), value: role }}
            initialValid={true}
          />
        </div>
      </Modal>
    </>
  );
};

export default UserCardActions;
