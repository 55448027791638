import React from "react";
import ReactDom from "react-dom";

import { Link } from "react-router-dom";

import "../../../css/Tabs.css";

const RoomsListTabs = props => {
  const content = (
    <div className="border-top">
      <div className="container">
        <Link to="/rooms/cooling">
          <button
            className={`stealth-button tab ${
              props.isCooling ? "tab-active" : ""
            }`}
          >
            KÜHLUNG
          </button>
        </Link>
        <Link to="/rooms/hygene">
          <button
            className={`stealth-button tab ${
              props.isHygene ? "tab-active" : ""
            }`}
          >
            HYGIENE
          </button>
        </Link>
        <Link to="/rooms/settings">
          <button
            className={`stealth-button tab ${
              props.isSettings ? "tab-active" : ""
            }`}
          >
            EINSTELLUNGEN
          </button>
        </Link>
      </div>
    </div>
  );
  return ReactDom.createPortal(content, document.getElementById("tab-hook"));
}

export default RoomsListTabs;
