import React from "react";
import Button from "./Button";

import "./SuccessButton.css";

const SuccessButton = props => {
  let btnPrimary;
  props.outline ? btnPrimary = false : btnPrimary = true;
  return (
    <div
      style={{ width: props.width, height: props.height }}
      data-testid="successbtn"
    >
      <Button
        style={props.style}
        disabled={props.disabled}
        type={props.type || "submit"}
        primary={btnPrimary}
        outline={!btnPrimary}
        onClick={props.onClick}
        classes={`btn-block d-flex justify-content-center btn-fixed-height ${
          props.classes || ""
        }`}
        form={props.form}
      >
        {!props.isLoading && props.success && (
          <i className="far fa-check-circle align-self-center"></i>
        )}
        {!props.isLoading && !props.success && (
          <span className="initial-value align-self-center">
            {props.children}
          </span>
        )}
        {props.isLoading && (
          <div className="loader align-self-center">Loading...</div>
        )}
      </Button>
    </div>
  );
};

export default SuccessButton;
