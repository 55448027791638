import { useState } from "react";

export const useSuccess = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onStart = () => {
    setLoading(true);
  }

  const onSuccess = () => {
     setTimeout(() => {
       setSuccess(true);
       setLoading(false);
     }, 300);
     setTimeout(() => {
       setSuccess(false);
       setLoading(false);
      }, 2000);
  }

  const onError = () => {
    setSuccess(false);
    setLoading(false);
  }

  return { loading, success, onStart, onSuccess, onError };
};
