import React, { useContext } from "react";

import ErrorModal from '../../../shared/components/uiElements/ErrorModal'
import Select from "../../../shared/components/formElements/Select";
import SuccessButton from "../../../shared/components/formElements/SuccessButton";
import { useForm } from "../../../shared/hooks/form-hook";
import { VALIDATOR_REQUIRE } from "../../../shared/util/validators";
import { useSuccess } from "../../../shared/hooks/success-hook";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";

const DeleteRoom = props => {

  const auth = useContext(AuthContext);
  const { error, sendRequest, clearError } = useHttpClient();

  const options = props.rooms.map((room) => ({key: room.name, value: room.id}))

  const { loading, success, onStart, onSuccess, onError } = useSuccess();
  const [formState, inputHandler] = useForm({
    roomNameDelete: {
      value: "",
      isValid: false
    }
  }, false)

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      onStart();
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/rooms/${formState.inputs.roomNameDelete.value}/`,
        "DELETE",
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      onSuccess();
      props.deleteCallback(formState.inputs.roomNameDelete.value);
    } catch (error) {
      onError();
      // handled by useHttpClient hook
    }
  }

  return (
    <>
    <ErrorModal error={error} onClear={clearError} />
    <form onSubmit={submitHandler} style={{ height: "100px" }}>
      <div className="mt-3" style={{ paddingTop: "40px" }}>
        <div className="row justify-content-start align-items-center">
          <Select
            classes="bg-light"
            col="col-7"
            id="roomNameDelete"
            element="input"
            type="text"
            validators={[VALIDATOR_REQUIRE()]}
            options={options}
            onInput={inputHandler}
            resetValue={""}
            resetValid={false}
            reset={success}
          />
          <SuccessButton
            classes="btn-danger ml-2 rsp-btn"
            isLoading={loading}
            success={success}
            type="submit"
            width="150px"
          >
            Raum entfernen
          </SuccessButton>
        </div>
      </div>
    </form>
    </>
  )
}

export default DeleteRoom;
