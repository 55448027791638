import React, { useEffect } from "react";
import { useInput } from "../../hooks/input-hook";

const Input = (props) => {

  const { inputState, changeHandler, touchHandler, resetHandler } = useInput(props)

  const { id, onInput } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  useEffect(() => {
    if (props.reset) {
      if (props.resetValue) {
        resetHandler(props.resetValue, props.resetValid);
        document.getElementById(props.id).value = props.resetVal
      } else {
        resetHandler("", props.resetValid);
        document.getElementById(props.id).value = ""
      }
    }
  }, [props.reset, props.id, props.resetVal, props.resetValid, props.resetValue, resetHandler])

  const element =
    props.element === "input" ? (
      <input
        step={props.step}
        onChange={props.onChange || changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
        className={`form-control ${
          !inputState.isValid && inputState.isTouched && "is-invalid"
        } ${props.classes}`}
        id={props.id}
        type={props.type}
        placeholder={props.placeholder}
      />
    ) : (
      <textarea
        data-testid="textarea"
        onChange={props.onChange || changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
        id={props.id}
        rows={props.rows || 3}
        className={`form-control ${
          !inputState.isValid && inputState.isTouched && "is-invalid"
        } ${props.classes}`}
      />
    );
  return (
      <div className={props.col}>
        <div className="form-group">
          {props.label &&
          <label
            className={`my-2 ${
              !inputState.isValid && inputState.isTouched && "text-danger"
            }`}
            htmlFor={props.id}
          >
            {props.label}
          </label> }
          {element}
          <div className="invalid-feedback">{props.errorText}</div>
        </div>
      </div>
  );
};
export default Input;
