import React from 'react'
import { useParams } from "react-router-dom";

import CleaningStepsList from '../components/roomShow/CleaningStepsList';
import RoomTabs from '../components/roomShow/RoomTabs';

const RoomHygeneSettings = () => {
  const roomId = useParams().roomId;
    return (
      <>
        <RoomTabs
          overviewLink="/rooms/hygene"
          isSettings
          roomId={roomId}
          url="hygene"
        />
        <CleaningStepsList roomId={roomId} />
      </>
    );
}

export default RoomHygeneSettings
