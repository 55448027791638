import React from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormCheck from 'react-bootstrap/FormCheck';


const DefectsChecks = props => {

  const changeHandler = (event) => {
    let defects = [];
    const defectsNodes = event.target.parentNode.parentNode.parentNode.parentNode;
    defectsNodes.querySelectorAll("input").forEach((input) => {
      if (input.checked) {
        defects.push(input.value)
      }
    })
    props.defectsCallback(defects)
  }


  return (
    <div>
      <Row>
        <Col>
          <FormCheck inline label="Keule (links)" type="checkbox" value="legLeft" onChange={changeHandler} />
        </Col>
        <Col>
          <FormCheck inline label="Keule (rechts)" type="checkbox" value="legRight" onChange={changeHandler} />
        </Col>
        <Col>
          <FormCheck inline label="Rücken (vorne)" type="checkbox" value="backFront" onChange={changeHandler} />
        </Col>
        <Col>
          <FormCheck inline label="Rücken (hinten)" type="checkbox" value="backBack" onChange={changeHandler} />
        </Col>
      </Row>
      <br/>
      <Row>
        <Col xs={3}>
          <FormCheck inline label="Schmutz (leicht)" type="checkbox" value="dirtLight" onChange={changeHandler} />
        </Col>
        <Col xs={3}>
          <FormCheck inline label="Schmutz (stark)" type="checkbox" value="dirtStrong" onChange={changeHandler} />
        </Col>
        <Col xs={4} >
          <FormCheck inline label="Rauschig / Brunftig" type="checkbox" value="rutting" onChange={changeHandler} />
        </Col>
        <Col xs={1}></Col>
      </Row>
    </div>
  );
}

export default DefectsChecks;
