import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from 'moment';

import { useReminder } from "../../hooks/reminder-hook";

const RoomCardHygene = props => {
  const csArray = props.room.cleaningSteps;
  const { getDueDate } = useReminder();
  const [dueToday, setDueToday] = useState();

  useEffect(() => {
    let dueDates = csArray.map((cs) => {
       if (cs.lastCheckedAt !== undefined && getDueDate(cs.lastCheckedAt, cs.repeat) !== "Manuell") {
         return moment(getDueDate(cs.lastCheckedAt, cs.repeat)).format(
           "DD-MM-yyyy"
         );
       }
       return null;
     })
     dueDates = dueDates.filter(date => date !== undefined)
     const dueToday = dueDates.filter(d => d === moment(new Date(Date.now())).format("DD-MM-yyyy")).length;
     setDueToday(dueToday);
  }, [csArray, getDueDate])



  return (
    <div className="col-auto mb-3">
      <div className="card border-secondary mb-3" style={{ width: "18rem" }}>
        <div className="card-header d-flex justify-content-between">
          <div>
            {props.room.cold && (
              <i
                className="far fa-snowflake text-primary"
                style={{ fontSize: "1rem" }}
              ></i>
            )}
            {!props.room.cold && (
              <i
                className="fas fa-circle text-secondary"
                style={{ fontSize: "1rem" }}
              ></i>
            )}{" "}
            {props.room.name}
          </div>
          <Link to={`/${props.room.id}/hygene`}>
            <i className="fas fa-ellipsis-h"></i>
          </Link>
        </div>
        <div className="card-body text-secondary">
          <h5 className="card-title">Heute fällig: {dueToday}</h5>
          <p className="card-text">Some more info, dunno what rn.</p>
        </div>
      </div>
    </div>
  );
}

export default RoomCardHygene;
