import React from 'react'

import VetOfficeSettingsTabs from "../components/VetOfficeSettingsTabs";
import UserList from "../components/UserList";

const VetOfficeTeamSettings = () => {
  return (
    <>
      <VetOfficeSettingsTabs isTeam />
      <p className="h4">Team verwalten</p>
      <UserList />
      <br />
    </>
  );
}

export default VetOfficeTeamSettings;
