import React, { useEffect, useContext, useState } from "react";
import Spinner from "react-bootstrap/Spinner";

import ErrorModal from "../../shared/components/uiElements/ErrorModal";
import Select from "../../shared/components/formElements/Select";
import SuccessButton from "../../shared/components/formElements/SuccessButton";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { useSuccess } from "../../shared/hooks/success-hook";

import "../../css/SettingsBtn.css";

const SetVetOffice = () => {
  const auth = useContext(AuthContext);
  const [loadedVets, setLoadedVets] = useState(null);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { loading, success, onStart, onSuccess, onError } = useSuccess();

  const [formState, inputHandler] = useForm(
    {
      vetOfficeId: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  useEffect(() => {
    const fetchVetOffices = async () => {
      let res;
      try {
        res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/companies/vetoffices",
          "GET",
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );

        const options = res.vetOffices.map((vetOffice) => ({
          key: vetOffice.companyName,
          value: vetOffice.id,
        }));

        setLoadedVets(options);
      } catch (error) {
        // handled by useHttpClient hook
      }
    };
    fetchVetOffices();
  }, [auth.token, sendRequest]);

  const submitHandler = async (event) => {
    event.preventDefault();
    onStart();
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/companies/addvetoffice",
        "POST",
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        JSON.stringify({ vetOfficeId: formState.inputs.vetOfficeId.value })
      );
      onSuccess();
    } catch (error) {
      // handled by useHttpClient hook
      onError();
    }
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="d-flex justify-content-center loading-screen">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      {loadedVets && (
        <form onSubmit={submitHandler}>
          <div className="row justify-content-start align-items-center">
            <Select
              col="col-7"
              classes="bg-light"
              id="vetOfficeId"
              onInput={inputHandler}
              options={loadedVets}
              validators={[]}
            />
            <SuccessButton
              classes="rsp-btn"
              disabled={!formState.isValid}
              isLoading={loading}
              success={success}
              width="150px"
            >
              Speichern
            </SuccessButton>
          </div>
        </form>
      )}
    </React.Fragment>
  );
}

export default SetVetOffice;
