import React, { useContext, useState } from "react";
import ReactDom from "react-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { AuthContext } from "../../shared/context/auth-context";
import { Link } from "react-router-dom";

const Navigation = () => {
  const auth = useContext(AuthContext);
  const [expanded, setExpanded] = useState(false);

  const content = (
    <Navbar
      expanded={expanded}
      bg="light"
      expand="xs"
      sticky="top"
    >
      <Navbar.Brand href="/" className="logo">
        Alma Farm
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={() => setExpanded((prevMode) => !prevMode)}
      />
      <Navbar.Collapse id="basic-navbar-nav">
        <br />
        <Nav className="mr-auto nav-bar">
          {auth.userRole !== "vet" && auth.userRole !== "vetOfficial" && (
            <Link
              to="/profilesettings"
              onClick={() => setExpanded((prevMode) => !prevMode)}
            >
              <p className="h5 text-center">Einstellungen</p>
            </Link>
          )}
          {auth.userRole === "vet" && (
            <Link
              to="/vetsettings"
              onClick={() => setExpanded((prevMode) => !prevMode)}
            >
              <p className="h5 text-center">Einstellungen</p>
            </Link>
          )}
          {auth.userRole === "vetOfficial" && (
            <Link
              to="/vetoffice/settings/profile"
              onClick={() => setExpanded((prevMode) => !prevMode)}
            >
              <p className="h5 text-center">Einstellungen</p>
            </Link>
          )}
          <br />
          {auth.isLoggedIn && (
            <button className="stealth-button" onClick={() => setExpanded((prevMode) => !prevMode)}>
            <Nav.Link href="#" onClick={auth.logout}>
              <p className="h5 text-center">Ausloggen</p>
            </Nav.Link>
            </button>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
  return ReactDom.createPortal(content, document.getElementById("nav-hook"));
};

export default Navigation;
