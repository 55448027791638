import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import SupplierEditForm from "./SupplierEditForm";

const SupplierCard = (props) => {
  const [checked, setChecked] = useState(false);

  const onChange = () => {
    if (checked === false) {
      setChecked(true);

    } else {
      setChecked(false);

    }
  };

  const editCallback = (inputs, id) => {
    props.editCallback(inputs, id);
    setChecked(false);
  }


  if (props.length === 0) {
    return (
      <div>
        <p>No Suppliers Found</p>
      </div>
    );
  }
  return (
    <div>
      <Accordion defaultActiveKey="0" className="mb-3">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey={props.sup.id}>
            {props.sup.supplier_name}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={props.sup.id}>
            <Card.Body>
              <div className="d-flex justify-content-end mb-4">
                <label>Bearbeiten</label>
                <input
                  className="mt-2 ml-2"
                  type="checkbox"
                  name="edit-info"
                  id="edit-info"
                  onChange={() => onChange()}
                  checked={checked}
                />
              </div>
              {checked ? (
                <SupplierEditForm currentSupplier={props.sup} editCallback={editCallback}/>
              ) : (
                <div>
                  <div className="row">
                    <div className="col-6">
                      <p className="supplier-card-list-p">Name</p>
                      <p className="border p-2 rounded">
                        {props.sup.supplier_name ? props.sup.supplier_name : "---"}
                      </p>
                    </div>
                    <div className="col-6">
                      <p className="supplier-card-list-p">Adresse</p>
                      <p className="border p-2 rounded">
                        {props.sup.addr_line ? props.sup.addr_line : "---"}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <p className="supplier-card-list-p">PLZ</p>
                      <p className="border p-2 rounded">
                        {props.sup.postal_code ? props.sup.postal_code : "---"}
                      </p>
                    </div>
                    <div className="col-6">
                      <p className="supplier-card-list-p">Stadt</p>
                      <p className="border p-2 rounded">{props.sup.city ? props.sup.city : "---"}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <p className="supplier-card-list-p">Telefon</p>
                      <p className="border p-2 rounded">{props.sup.tel ? props.sup.tel : "---"}</p>
                    </div>
                    <div className="col-6">
                      <p className="supplier-card-list-p">Email</p>
                      <p className="border p-2 rounded">{props.sup.email ? props.sup.email : "---"}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <p className="supplier-card-list-p">IBAN</p>
                      <p className="border p-2 rounded">{props.sup.iban ? props.sup.iban : "---"}</p>
                    </div>
                    <div className="col-6">
                      <p className="supplier-card-list-p">BIC</p>
                      <p className="border p-2 rounded">{props.sup.bic ? props.sup.bic : "---"}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <p className="supplier-card-list-p">Landkreise</p>
                      <p className="border p-2 rounded">
                        {props.sup.admin_district ? props.sup.admin_district : "---"}
                      </p>
                    </div>
                    <div className="col-6">
                      <p className="supplier-card-list-p">Jagdbezirke</p>
                      <p className="border p-2 rounded">
                        {props.sup.hunting_district ? props.sup.hunting_district : "---"}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};

export default SupplierCard;
