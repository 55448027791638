import React from 'react'

import SettingsTabs from "../components/SettingsTabs";
import SetVet from "../components/SetVet";
import SetVetOffice from "../components/SetVetOffice";

const PartnerSettings = () => {
  return (
    <>
      <SettingsTabs isPartner />
      <div className="bg-light p-5 mt-5 rounded">
        <p className="h4">Veterinär verknüpfen</p>
        <hr />
        <br />
        <SetVet />
      </div>
      <div className="bg-light p-5 mt-5 rounded">
        <p className="h4">Veterinäramt verknüpfen</p>
        <hr />
        <br />
        <SetVetOffice />
      </div>
    </>
  );
}

export default PartnerSettings;
