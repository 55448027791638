import React, { useContext } from 'react'
import Form from "react-bootstrap/Form";

import ErrorModal from '../../../shared/components/uiElements/ErrorModal'
import Input from '../../../shared/components/formElements/Input';
import Checkbox from '../../../shared/components/formElements/Checkbox';
import SuccessButton from '../../../shared/components/formElements/SuccessButton'
import Button from '../../../shared/components/formElements/Button'
import { useForm } from "../../../shared/hooks/form-hook";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";

const UpdateRoom = props => {
  const auth = useContext(AuthContext);
  const { error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm({
    name: {
      value: props.room.name,
      isValid: true
    },
    cold: {
      value: props.room.cold,
      isValid: true
    }
  }, true)

  const submitHandler = async (event) => {
    event.preventDefault();
     try {
       await sendRequest(
         `${process.env.REACT_APP_BACKEND_URL}/rooms/${props.roomId}`,
         "PATCH",
         {
           "Content-Type": "application/json",
           authorization: "Bearer " + auth.token,
         },
         JSON.stringify({
           name: formState.inputs.name.value,
           cold: formState.inputs.cold.value
         })
       );
       props.updateRoomCallback(formState.inputs);
     } catch (error) {
       // handled by useHttpClient hook
     }
  }


  return (
    <>
    <ErrorModal error={error} onClear={clearError}/>
    <Form onSubmit={submitHandler} inline className="ml-3">
      <Input
        id="name"
        element="input"
        type="text"
        onInput={inputHandler}
        validators={[]}
        initialValid={true}
        initialValue={props.room.name}
        classes="form-control-lg mr-3"
      />
      <Checkbox
        id="coldRoom"
        type="checkbox"
        label="Kühlraum"
        validators={[]}
        onInput={inputHandler}
        initialValue={props.room.cold}
        initialValid={true}
      />
      <Button outline onClick={props.onCancel} classes="ml-3">
        Abbrechen
      </Button>
      <SuccessButton classes="ml-3">
        Speichern
      </SuccessButton>
    </Form>
    </>
  );
}

export default UpdateRoom;
