import React, { useContext } from 'react'

import ErrorModal from '../../../shared/components/uiElements/ErrorModal'
import Input from "../../../shared/components/formElements/Input";
import Checkbox from "../../../shared/components/formElements/Checkbox";
import SuccessButton from "../../../shared/components/formElements/SuccessButton";
import { useForm } from "../../../shared/hooks/form-hook";
import { VALIDATOR_REQUIRE } from "../../../shared/util/validators";
import { useSuccess } from "../../../shared/hooks/success-hook";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";

const AddRoom = props => {
    const auth = useContext(AuthContext);
    const { error, sendRequest, clearError } = useHttpClient();

    const { loading, success, onStart, onSuccess, onError } = useSuccess();
    const [formState, inputHandler] = useForm(
      {
        roomName: {
          value: "",
          isValid: false,
        },
        coldRoom: {
          value: false,
          isValid: true,
        },
      },
      false
    );

    const submitHandler = async (event) => {
      event.preventDefault();
      try {
        onStart();
        await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/rooms",
          "POST",
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
          JSON.stringify({
            cold: formState.inputs.coldRoom.value,
            name: formState.inputs.roomName.value,
          })
        );
        props.addCallback(formState.inputs);
        onSuccess();
      } catch (error) {
        onError();
        // handled by useHttpClient hook
      }
    };

    return (
      <>
      <ErrorModal error={error} onClear={clearError} />
      <form onSubmit={submitHandler} style={{ height: "100px" }}>
        <Checkbox
          id="coldRoom"
          type="checkbox"
          label="Kühlraum"
          validators={[]}
          onInput={inputHandler}
          initialValue={false}
          initialValid={true}
          resetValue={false}
          resetValid={true}
          reset={success}
        />
        <div className="mt-3">
          <div className="row justify-content-start align-items-center">
            <Input
              classes="bg-light"
              col="col-7"
              id="roomName"
              element="input"
              type="text"
              validators={[VALIDATOR_REQUIRE()]}
              onInput={inputHandler}
              placeholder="Raumname"
              resetValue={""}
              resetValid={false}
              reset={success}
            />
            <SuccessButton
              isLoading={loading}
              success={success}
              type="submit"
              primary
              classes="ml-2 rsp-btn"
              width="150px"
            >
              Raum anlegen
            </SuccessButton>
          </div>
        </div>
      </form>
      </>
    );
}

export default AddRoom;
