import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import CleaningStepSettingsCardActions from "./CleaningStepSettingsCardActions";
import { REPEAT_OBJECT } from "../../../shared/util/converterObjects";

const CleaningStepsCard = (props) => {
  const cs = props.cleaningStep;
  return (
    <Card className="p-3 m-3">
      <Card.Header as="h5" className="d-flex justify-content-between">
        {props.index + 1}{" "}
        <CleaningStepSettingsCardActions
          cleaningStep={cs}
          roomId={props.roomId}
          deleteCallback={props.deleteCallback}
          updateCallback={props.updateCallback}
        />
      </Card.Header>
      <Card.Body>
        <Row className="text-muted">
          <Col xs={3}>Bereich</Col>
          <Col>Wiederholung</Col>
          <Col xs={5}>Reinigung / Desinfektion</Col>
        </Row>
        <Row>
          <Col xs={3}>
            <strong>{cs.area}</strong>
          </Col>
          <Col>
            <strong>{REPEAT_OBJECT[cs.repeat]}</strong>
          </Col>
          <Col xs={5}>
            <strong>{cs.kind}</strong>
          </Col>
        </Row>
        <Row className="text-muted">
          <Col xs={3}>Anweisung</Col>
          <Col>Reinigunsmittel</Col>
          <Col xs={5}>Konzentration / Einwirkzeit / Temperatur</Col>
        </Row>
        <Row>
          <Col xs={3}>
            <strong>{cs.instruction}</strong>
          </Col>
          <Col>
            <strong>{cs.cleaningAgent}</strong>
          </Col>
          <Col xs={5}>
            <strong>{cs.concentration ? cs.concentration : "-"}</strong> %
            / <strong>{cs.applicationTime ? cs.applicationTime : "-"}</strong> min
            / <strong>{cs.temperature ? cs.temperature : "-"}</strong> °C
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CleaningStepsCard;
