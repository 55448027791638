import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import UserCardActions from "./UserCardActions";

import "./CreateUser.css";

const UserCard = (props) => {

  const oneDayAgo = (date) => {
    const day = 1000 * 60 * 60 * 24;
    const dayAgo = Date.now() - day;

    return date > dayAgo;
  };


  let userStatus;
  let currentUserStatus;
  if (
    props.userData.status === "pending" &&
    oneDayAgo(new Date(props.userData.createdAt))
  ) {
    userStatus = (
      <p>
        <i className="fas fa-circle text-secondary"></i> <span className="status-text">Ausstehend</span>
      </p>
    );
    currentUserStatus = "pending";
  }

  if (
    props.userData.status === "pending" &&
    !oneDayAgo(new Date(props.userData.createdAt))
  ) {
    userStatus = (
      <p>
         <i className="fas fa-circle text-danger"></i> <span className="status-text">Abgelaufen</span>
      </p>
    );
    currentUserStatus = "expired";
  }

  if (props.userData.status === "approved") {
    userStatus = (
      <p>
        <i className="fas fa-circle text-success"></i> <span className="status-text">Aktiv</span>
      </p>
    );
    currentUserStatus = "active";
  }

  if (props.userData) {
    return (
      <div>
        <Card className="mt-2 mb-2 p-3">
          <Row>
            <Col xs={2} >{userStatus}</Col>
            <Col xs={2}>{props.userData.role === "vetOfficial" ? "ADMIN" : props.userData.role.toUpperCase()}</Col>
            <Col xs={2}>
              {`${props.userData.firstName ? props.userData.firstName : ""} ${props.userData.lastName ? props.userData.lastName : ""}`}
            </Col>
            <Col>{props.userData.email}</Col>
            <Col >
            <div className="d-flex justify-content-start text-secondary">
              <UserCardActions userData={props.userData} userActionsCallback={props.userActionsCallback} currentUserStatus={currentUserStatus} />
            </div>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
};

export default UserCard;
