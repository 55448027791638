import React, { useReducer, useEffect } from 'react'

const radioReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: true,
      };
    default:
      return state;
  }
};

const RadioGroup = props => {
   const [inputState, dispatch] = useReducer(radioReducer, {
     value: props.initialValue || "",
     isValid: props.initialValid || false,
   });

   const changeHandler = (event) => {
     dispatch({
       type: "CHANGE",
       val: event.target.value,
     });
   };

   const { id, onInput } = props;
   const { value, isValid } = inputState;

   useEffect(() => {
     onInput(id, value, isValid);
   }, [id, value, isValid, onInput]);

   useEffect(() => {
     if (props.initialValue) {
       document.querySelector(
         `input[value=${props.initialValue}]`
       ).checked = true;
     }
   }, [props.initialValue])


  return (
    <div className="my-2">
      {props.items.map((radio) => (
        <div
          key={radio.id}
          className={`form-check ${props.inline ? "form-check-inline" : ""}`}
        >
          <input
            id={radio.id}
            name={props.name}
            type="radio"
            className="form-check-input"
            onChange={changeHandler}
            value={radio.value}
            validators={[]}
          />
          <label className="form-check-label" htmlFor={radio.id}>
            {radio.label}
          </label>
        </div>
      ))}
    </div>
  );
}

export default RadioGroup;
