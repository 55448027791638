import React from "react";
import { Link } from "react-router-dom";

const Button = (props) => {


  if (props.href) {
    return (
      <a href={props.href} className={`btn ${props.classes || ""}`}>
        {props.children}
      </a>
    );
  }
  if (props.to) {
    return (
      <Link
        to={props.to}
        exact={props.exact}
        className={`btn ${props.classes || ""}`}
      >
        {props.children}
      </Link>
    );
  }
  return (
    <button
      className={`
      ${
        (props.primary && "btn btn-primary") ||
        (props.outline && "btn btn-outline-primary") ||
        (props.danger && "btn btn-danger")
      } ${props.classes || ""}`}
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
      style={props.style}
      form={props.form}
    >
      {props.children}
    </button>
  );
};

export default Button;
