import React from 'react'
import ReactDom from "react-dom";
import { Link } from "react-router-dom";

import "../../css/Tabs.css";

const SettingsTabs = props => {
   const content = (
     <div className="border-top">
       <div className="container">
         <Link to={`/profilesettings`}>
           <button
             className={`stealth-button tab ${
               props.isProfile ? "tab-active" : ""
             }`}
           >
             PROFIL
           </button>
         </Link>
         <Link to={`/teamsettings`}>
           <button
             className={`stealth-button tab ${
               props.isTeam ? "tab-active" : ""
             }`}
           >
             TEAM
           </button>
         </Link>
         <Link to={`/partnersettings`}>
           <button
             className={`stealth-button tab ${
               props.isPartner ? "tab-active" : ""
             }`}
           >
             PARTNER
           </button>
         </Link>
       </div>
     </div>
   );
   return ReactDom.createPortal(content, document.getElementById("tab-hook"));
}

export default SettingsTabs;
