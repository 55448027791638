import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";

import ErrorModal from '../../shared/components/uiElements/ErrorModal'
import SuccessButton from "../../shared/components/formElements/SuccessButton";
import FileUpload from "../../shared/components/formElements/FileUpload";
import RoomTabs from "../components/roomShow/RoomTabs";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useForm } from "../../shared/hooks/form-hook";

const RoomCoolingSettings = () => {
  const auth = useContext(AuthContext);
  const roomId = useParams().roomId;
  const { error, sendRequest, clearError } = useHttpClient();
   const [formState, inputHandler] = useForm(
     {
       csv: {
         value: null,
         isValid: false,
       },
     },
     false
   );

   const submitHandler = async (event) => {
     event.preventDefault();
     const formData = new FormData();
     formData.append("file", formState.inputs.csv.value);
     try {
       await sendRequest(
         process.env.REACT_APP_BACKEND_URL + "/rooms/tempdata",
         "POST",
         {
           Authorization: "Bearer " + auth.token,
         },
         formData
       );
     } catch (error) {
       // handled by useHttpClient hook
     }
   };

  return (
    <>
    <ErrorModal error={error} onClear={clearError} />
      <RoomTabs
        overviewLink="/rooms/cooling"
        isSettings
        roomId={roomId}
        url="cooling"
      />
      <div className={`settings-section bg-white rounded-lg p-5 mt-5`}>
        <p className="h4">Kühlung verwalten</p>
        <p className="w-75">
          Hier können Sie Aufzeichnungen von Temperaturfühlern im CSV format
          hochladen.
        </p>
        <hr className="mb-4" />
        <form onSubmit={submitHandler}>
          <div className="d-flex">
            <div className="col-7">
              <FileUpload id="csv" onInput={inputHandler} />
            </div>
            <div style={{ height: "40px" }}>
              <SuccessButton classes="ml-3" primary width="150px">
                Hochladen
              </SuccessButton>
            </div>
          </div>
        </form>
        <hr className="mt-4 mb-1" />
      </div>
    </>
  );
}

export default RoomCoolingSettings;
