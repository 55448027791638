import React, { useEffect, useState, useContext } from 'react'
import { useParams } from "react-router-dom";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';

import ErrorModal from '../../shared/components/uiElements/ErrorModal'
import RoomTabs from '../components/roomShow/RoomTabs';
import CleaningStepDashCard from '../components/roomShow/CleaningStepDashCard';
import CleaningStepHeaderDashCard from '../components/roomShow/CleaningStepHeaderDashCard'
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const RoomHygene = () => {
  const roomId = useParams().roomId;
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError} = useHttpClient();
  const [loadedCleaningSteps, setLoadedCleaningSteps] = useState();
  const [roomInfo, setRoomInfo] = useState();

  useEffect(() => {
    const fetchCleaningSteps = async () => {
      try {
        const res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            "/rooms/" +
            roomId +
            "/cleaningsteps",
          "GET",
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        setLoadedCleaningSteps(res.cleaningSteps);
        setRoomInfo({ name: res.roomName, cold: res.roomCold });
      } catch (error) {
        // handled by useHttpClient hook
      }
    };
    fetchCleaningSteps();
  }, [auth.token, roomId, sendRequest]);

  const cleaningCheckCallback = (cleaningStepId) => {
    let updatedCleaningSteps = Object.assign([], loadedCleaningSteps);
    const index = updatedCleaningSteps.findIndex(step => step.id === cleaningStepId);
    updatedCleaningSteps[index].lastCheckedAt = moment(new Date()).format("yyyy-MM-DD");
    setLoadedCleaningSteps(updatedCleaningSteps);
  }



  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      <RoomTabs
        overviewLink="/rooms/hygene"
        isDash
        roomId={roomId}
        url="hygene"
      />
      {roomInfo && (
        <>
          <p className="h2 ml-3">{roomInfo.name}</p>
          <p className="ml-3">
            <i
              className="fas fa-square text-success mr-1"
              style={{ fontSize: "1rem" }}
            ></i>{" "}
            heute durchgeführt
            <i
              className="fas fa-square text-warning mr-1 ml-3"
              style={{ fontSize: "1rem" }}
            ></i>{" "}
            heute fällig
            <i
              className="fas fa-square text-danger mr-1 ml-3"
              style={{ fontSize: "1rem" }}
            ></i>{" "}
            überfällig
            <i
              className="fas fa-square text-primary mr-1 ml-3"
              style={{ fontSize: "1rem" }}
            ></i>{" "}
            manuell
            <i
              className="fas fa-square text-secondary mr-1 ml-3"
              style={{ fontSize: "1rem" }}
            ></i>{" "}
            alle
          </p>
        </>
      )}
      <CleaningStepHeaderDashCard/>
      {loadedCleaningSteps &&
        loadedCleaningSteps.map((cs, index) => (
          <CleaningStepDashCard
            cleaningCheckCallback={cleaningCheckCallback}
            cleaningStep={cs}
            index={index}
            key={cs.id}
            roomId={roomId}
          />
        ))}
    </>
  );
}

export default RoomHygene;
