import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import moment from 'moment';
import { Line } from "react-chartjs-2";

import RoomTabs from '../components/roomShow/RoomTabs';
import RangeFilter from '../../shared/components/uiElements/RangeFilter'
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const RoomCooling = props => {
  const roomId = useParams().roomId;
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const [chartData, setChartData] = useState({});
  const firstDay = new Date(moment().subtract(1, "weeks").startOf("isoWeek")._d)
  const lastDay =  new Date(moment().subtract(1, "weeks").endOf("isoWeek")._d)
  const [roomName, setRoomName] = useState();
  const [dateRange, setDateRange] = useState({
    start: firstDay,
    stop: lastDay
  });
  const [requestDateRange, setRequestDateRange] = useState({
    start: firstDay,
    stop: lastDay,
  });

  useEffect(() => {
    const fetchData = async () => {
      let res;
      try {
        res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/rooms/${roomId}/tempdata`,
          "GET",
          { Authorization: "Bearer " + auth.token },
          null,
          {
            start: moment(dateRange.start).format("X"),
            stop: moment(dateRange.stop).format("X")
          }
        );
        setRoomName(res.roomName);

        let code14Data = [];
        let data = [];
        Object.entries(res.queryResponse).forEach(entry => {
          if (entry[1].code === 14) {
            code14Data.push(entry[1].temp)
            data.push(null)
          } else {
            code14Data.push(null)
            data.push(entry[1].temp)
          }
        })

        const localLabels = Object.entries(res.queryResponse).map(
          (entry) => moment(entry[0]).utcOffset(2)
        );


        setChartData({
          labels: localLabels,
          datasets: [
            {
              label: "Temperatur bei Abtauung",
              data: code14Data,
              borderColor: "rgba(243, 176, 6, 1)",
              backgroundColor: "rgba(243, 176, 6, 0)",
              borderWidth: 2.5,
              lineTension: 0,
              pointRadius: 0,
            },
            {
              label: "Temperatur",
              data: Object.entries(res.queryResponse).map(
                (entry) => entry[1].temp
              ),
              borderColor: "rgba(0, 105, 217, 1)",
              borderWidth: 2,
              backgroundColor: "rgba(0, 105, 217, 0.03)",
              pointRadius: 0,
              lineTension: 0
            },
            // {
            //   label: "5°C",
            //   data: Object.entries(res.queryResponse).map(
            //     (entry) => 5
            //   ),
            //   borderColor: "rgba(134, 248, 108, 1)",
            //   borderWidth: 1,
            //   fill: false,
            //   pointRadius: 0,
            // },
          ],
        });
      } catch (error) {}
    };
    if (Object.keys(chartData).length === 0 && chartData.constructor === Object) {
      // re-runs on date range change
      // only refetch if date range is outside of last fetched date range
      if (
        dateRange.start < requestDateRange.start ||
        dateRange.stop > requestDateRange.stop
      ) {
        setRequestDateRange(dateRange);
        fetchData();
      }
    } else {
      // runs fetch on page load, when chartData was not set before
      fetchData();
    }
  }, [auth.token, roomId, sendRequest, dateRange]);
  // here ES lint wants to add some dependencies that are left out on purpose.


  const dateChangeCallback = (inputs) => {
    setDateRange({
      start: inputs.start.value,
      stop: inputs.stop.value
    })
  }


  return (
    <div>
      <RoomTabs
        overviewLink="/rooms/cooling"
        isDash
        roomId={roomId}
        url="cooling"
      />
      <p className="h2">{roomName}</p>
      <hr />
      <br />
      <Line
        data={chartData}
        options={{
          scales: {
            xAxes: [
              {
                type: "time",
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 20,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  callback: function (value, index, values) {
                    return value + " °C";
                  },
                },
              },
            ],
          },
        }}
      />
      <div className="settings-section bg-white rounded-lg p-5 mt-5">
        <p className="h5">Zeitraum auswählen</p>
        <br />
        <RangeFilter
          dateRange={dateRange}
          dateChangeCallback={dateChangeCallback}
        />
      </div>
      <br />
    </div>
  );
};

export default RoomCooling;
