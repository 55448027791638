import React from "react";
import ReactDom from "react-dom";
import { Link } from "react-router-dom";

import "../../css/Tabs.css";

const RawMaterialsTabs = (props) => {
  const content = (
    <div className="border-top">
      <div className="container">
        {props.origin === "rawmaterial" && (
          <Link to="/raw_materials/rawmaterialsnew">
            <button className={`stealth-button tab`}>
              <i className="fas fa-arrow-left" style={{ fontSize: "1rem" }}></i>{" "}
              ZURÜCK
            </button>
          </Link>
        )}
        <Link to="/supplier/supplier">
          <button
            className={`stealth-button tab ${
              props.isIndex ? "tab-active" : ""
            }`}
          >
            ÜBERSICHT
          </button>
        </Link>
        <Link to={`/supplier/suppliernew`}>
          <button
            className={`stealth-button tab ${
              props.isCreate ? "tab-active" : ""
            }`}
          >
            NEU ANLEGEN
          </button>
        </Link>
      </div>
    </div>
  );
  return ReactDom.createPortal(content, document.getElementById("tab-hook"));
};

export default RawMaterialsTabs;
