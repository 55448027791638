import React, { useContext } from "react";

import ErrorModal from '../../../shared/components/uiElements/ErrorModal'
import Input from "../../../shared/components/formElements/Input";
import Select from "../../../shared/components/formElements/Select";
import SuccessButton from "../../../shared/components/formElements/SuccessButton";
import RadioGroup from "../../../shared/components/formElements/RadioGroup";
import { useForm } from "../../../shared/hooks/form-hook";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import { VALIDATOR_REQUIRE } from "../../../shared/util/validators";
import { REPEAT_ARRAY } from "../../../shared/util/converterObjects";

const AddCleaningStep = props => {
  const auth = useContext(AuthContext);
  const { error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm(
    {
      kind: {
        value: "",
        isValid: false,
      },
      repeat: {
        value: "",
        isValid: false,
      },
      area: {
        value: "",
        isValid: false,
      },
      instruction: {
        value: "",
        isValid: false,
      },
      cleaningAgent: {
        value: "",
        isValid: false,
      },
      applicationTime: {
        value: "",
        isValid: true,
      },
      temperature: {
        value: "",
        isValid: true,
      },
      concentration: {
        value: "",
        isValid: true,
      }
    },
    false
  );

  const submitHandler = async (event) => {
    event.preventDefault();
      try {
        const newCleaningStep = {
          kind: formState.inputs.kind.value,
          repeat: formState.inputs.repeat.value,
          area: formState.inputs.area.value,
          instruction: formState.inputs.instruction.value,
          cleaningAgent: formState.inputs.cleaningAgent.value,
          applicationTime: formState.inputs.applicationTime.value,
          temperature: formState.inputs.temperature.value,
          concentration: formState.inputs.concentration.value
        };
        await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/rooms/" + props.roomId + "/cleaningsteps",
          "POST",
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
          JSON.stringify(newCleaningStep)
        );
        props.addCallback(newCleaningStep);
      } catch (error) {
        // handled by useHttpClient hook
      }
  }


  return (
    <div>
      <ErrorModal error={error} onClear={clearError} />
      <form onSubmit={submitHandler}>
        <div className="row">
          <div className="col-6">
            <RadioGroup
              name="kind"
              id="kind"
              items={[
                { id: "kindClean", label: "Reinigung", value: "clean" },
                {
                  id: "kindDisinfect",
                  label: "Desinfektion",
                  value: "disinfect",
                },
              ]}
              inline
              onInput={inputHandler}
            />
          </div>
          <Select
            col="col-6"
            id="repeat"
            label="Wiederholung"
            onInput={inputHandler}
            validators={[]}
            options={REPEAT_ARRAY}
          />
        </div>
        <Input
          id="area"
          element="input"
          type="text"
          label="Bereich"
          onInput={inputHandler}
          validators={[VALIDATOR_REQUIRE()]}
        />
        <Input
          id="instruction"
          element="input"
          type="text"
          label="Anweisung"
          onInput={inputHandler}
          validators={[VALIDATOR_REQUIRE()]}
        />
        <Input
          id="cleaningAgent"
          element="input"
          type="text"
          label="Reinigunsmittel"
          onInput={inputHandler}
          validators={[VALIDATOR_REQUIRE()]}
        />
        <div className="row">
          <Input
            col="col-4"
            id="applicationTime"
            element="input"
            type="number"
            label="Einwirkzeit (in min)"
            onInput={inputHandler}
            validators={[]}
            initialValid={true}
          />
          <Input
            col="col-4"
            id="temperature"
            element="input"
            type="number"
            label="Temperatur (in °C)"
            onInput={inputHandler}
            validators={[]}
            initialValid={true}
          />
          <Input
            col="col-4"
            id="concentration"
            element="input"
            type="number"
            label="Konzentration (in %)"
            onInput={inputHandler}
            validators={[]}
            initialValid={true}
          />
        </div>
        <br />
        <SuccessButton>Reinigungsschritt anlegen</SuccessButton>
      </form>
    </div>
  );
};

export default AddCleaningStep;
