import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import RawMaterialsEditForm from "./RawMaterialsEditForm";
import VetTestForm from "../components/VetTestForm";
import PDF from "./pdf.png";
import ErrorModal from "../../shared/components/uiElements/ErrorModal";
import { useAuth } from "../../shared/hooks/auth-hook";
import { useDownloader } from "../../shared/hooks/download-hook";
import { DEFECTS_OBJECT } from "../../shared/util/converterObjects";

import "../../css/Animation.css";

const RawMaterialsCard = (props) => {
  const { error, downloadHandler, clearError } = useDownloader(
    "/rawmaterials/pdf",
    "pdf"
  );
  const dateOptions = { year: "numeric", month: "numeric", day: "numeric" };
  const { token, userRole } = useAuth();
  const [showTest, setShowTest] = useState(false);
  const [showTestAnimation, setShowTestAnimation] = useState(false);
  const [alreadyTested, setAlreadyTested] = useState(true);
  const [checked, setChecked] = useState(false);
  const [checkedAnimation, setCheckedAnimation] = useState(false);

  let vetTestStatus;
  if (props.rm.testedAt === undefined) {
    vetTestStatus = (
      <div>
        <div className="d-none d-xl-block  btn btn-secondary btn-sm btn-width">
          Ausstehend
        </div>
        <div className="d-xl-none small-test-indicator-secondary"></div>
      </div>
    );
  } else {
    if (!props.rm.approved) {
      vetTestStatus = (
        <div>
          <div className="d-none d-xl-block  btn btn-danger btn-sm btn-width">
            Abgelehnt
          </div>
          <div className="d-xl-none small-test-indicator-danger"></div>
        </div>
      );
    } else {
      vetTestStatus = (
        <div>
          <div className="d-none d-xl-block  btn btn-success btn-sm btn-width">
            Freigegeben
          </div>
          <div className="d-xl-none small-test-indicator-success"></div>
        </div>
      );
    }
  }
  if (props.rm.vetId === undefined) {
    vetTestStatus = (
      <div>
        <div className="d-none d-xl-block  btn btn-outline-info btn-sm btn-width">
          Nicht zugewiesen
        </div>
        <div className="d-xl-none small-test-indicator-outline-info"></div>
      </div>
    );
  }
  if (props.rm.vetId === null) {
    vetTestStatus = (
      <div>
        <div className="d-none d-xl-block  btn btn-info btn-sm btn-width">
          kein Veterinär
        </div>
        <div className="d-xl-none small-test-indicator-info"></div>
      </div>
    );
  }

  const showTestHandler = () => {
    if (showTest === false) {
      setShowTestAnimation(true);
    } else {
      setShowTestAnimation(false);
    }
  };

  const alreadyTestedHandler = () => {
    if (props.rm.testedAt === undefined) {
      setAlreadyTested(true);
    } else {
      setAlreadyTested(false);
    }
  };

  useEffect(() => {
    alreadyTestedHandler();
  });

  const localDownloadHandler = async (event) => {
    event.stopPropagation();
    downloadHandler(
      { rid: event.target.getAttribute("data-rid") },
      `${event.target.getAttribute("data-rid")}.pdf`
    );
  };

  const testCallback2 = (inputs, id) => {
    props.onTest(inputs, id);
    setShowTestAnimation(false);
    setAlreadyTested(true);
  };

  const assignCallback2 = (inputs, id) => {
    props.assignCallback(inputs, id);
    setCheckedAnimation(false);
  };

  let gender;
  if (props.rm.gender === "m") {
    gender = "Männlich";
  } else {
    gender = "Weiblich";
  }
  if (token) {
    if (userRole === "vet" || userRole === "vetOfficial") {
      return (
        <div>
          <ErrorModal error={error} onClear={clearError} />
          <Accordion defaultActiveKey="0" className="mb-3">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey={props.rm.id}>
                <Row>
                  <Col>{props.rm.type}</Col>
                  <Col>
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        width: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {props.rm.supplierName}
                    </p>
                  </Col>
                  <Col>{props.rm.hunting_district}</Col>
                  <Col>{vetTestStatus}</Col>
                  <Col>
                    {new Date(props.rm.createdAt).toLocaleDateString(
                      "de-DE",
                      dateOptions
                    )}
                  </Col>
                  <button
                    className="stealth-button"
                    onClick={localDownloadHandler}
                  >
                    <img
                      data-rid={props.rm.id}
                      className="pdf-icon my-auto mr-3"
                      src={PDF}
                      alt="pdf icon"
                    />
                  </button>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={props.rm.id}>
                <Card.Body>
                  <div>
                    {alreadyTested && (
                      <div className="d-flex w-100 justify-content-end mt-3">
                        <div>
                          <label>Testergebnis eintragen</label>
                          <input
                            className="mt-2 ml-2"
                            type="checkbox"
                            name="edit-info"
                            id="edit-info"
                            onChange={showTestHandler}
                          />
                        </div>
                      </div>
                    )}
                    <CSSTransition
                      in={showTestAnimation}
                      timeout={400}
                      classNames="form-300"
                      unmountOnExit
                      onEnter={() => setShowTest(true)}
                      onExited={() => setShowTest(false)}
                    >
                      <div>
                        <div
                          className={`bg-light rounded p-4 mb-3 d-flex justify-content-center`}
                          style={{ maxWidth: "700px", margin: "0 auto" }}
                        >
                          {showTest && (
                            <VetTestForm
                              id={props.rm.id}
                              onSuccess={testCallback2}
                            />
                          )}
                        </div>
                      </div>
                    </CSSTransition>
                    <div className="row">
                      <div className="col-4">
                        <p className="supplier-card-list-p">
                          Jagdausübungsberechtigter
                        </p>
                        <p className="border p-2 rounded bg-light">
                          {props.rm.supplierName}
                        </p>
                      </div>
                      <div className="col-4">
                        <p className="supplier-card-list-p">
                          Strasse, Hausnummer
                        </p>
                        <p className="border p-2 rounded bg-light">
                          {props.rm.supplierInfo.addrLine}
                        </p>
                      </div>
                      <div className="col-4">
                        <p className="supplier-card-list-p">
                          Postleitzahl, Ort
                        </p>
                        <p className="border p-2 rounded bg-light">
                          {`${props.rm.supplierInfo.postalCode}, ${props.rm.supplierInfo.city}`}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-4">
                        <p className="supplier-card-list-p">
                          Landkreis / kreisfreihe Stadt
                        </p>
                        <p className="border p-2 rounded bg-light">
                          {props.rm.admin_district}
                        </p>
                      </div>
                      <div className="col-4">
                        <p className="supplier-card-list-p">
                          Gemeinde / Jagdbezirk
                        </p>
                        <p className="border p-2 rounded bg-light">
                          {props.rm.hunting_district}
                        </p>
                      </div>
                      <div className="col-4">
                        <p className="supplier-card-list-p">
                          Erlegungsdatum / -uhrzeit
                        </p>
                        <p className="border p-2 rounded bg-light">
                          {new Date(props.rm.killedAt).toLocaleDateString(
                            "de-DE"
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-3">
                        <p className="supplier-card-list-p">Wildart</p>
                        <p className="border p-2 rounded bg-light">
                          {props.rm.type}
                        </p>
                      </div>
                      <div className="col-3">
                        <p className="supplier-card-list-p">Geschlecht</p>
                        <p className="border p-2 rounded bg-light">{gender}</p>
                      </div>
                      <div className="col-3">
                        <p className="supplier-card-list-p">Gewicht</p>
                        <p className="border p-2 rounded bg-light">
                          {props.rm.weight / 1000} Kg
                        </p>
                      </div>
                      <div className="col-3">
                        <p className="supplier-card-list-p">Altersklasse</p>
                        <p className="border p-2 rounded bg-light">
                          {props.rm.age}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <p className="supplier-card-list-p">Bemerkung</p>
                        <p className="border p-2 rounded bg-light">
                          {props.rm.comment ? props.rm.comment : "---"}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 d-flex">
                        <div className="my-auto mr-3">
                          {props.rm.behavioralDisorder ? <p>☒</p> : <p>☑</p>}
                        </div>
                        <p className="supplier-card-list-p">
                          Vor dem Erlegen wurden keine Verhaltenstörungen des
                          Tieres beobachtet.
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 d-flex">
                        <div className="my-auto mr-3">
                          {props.rm.distinctFeatures ? <p>☒</p> : <p>☑</p>}
                        </div>
                        <p className="supplier-card-list-p">
                          Bei der Untersuchung des Tieres wurden keine Merkmale
                          beobachtet, die das Fleisch als bedenklich zum Verzehr
                          durch Menschen erscheinen lassen.
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 d-flex">
                        <div className="my-auto mr-3">
                          {props.rm.contamination ? <p>☒</p> : <p>☑</p>}
                        </div>
                        <p className="supplier-card-list-p">
                          Ein Verdacht auf Umweltkontamation liegt nicht vor.
                        </p>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      );
    }
  }
  return (
    <div>
      <Accordion defaultActiveKey="0" className="mb-3">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey={props.rm.id}>
            <Row>
              <Col>{props.rm.type}</Col>
              <Col>
                <p
                  style={{
                    whiteSpace: "nowrap",
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {props.rm.supplierName}
                </p>
              </Col>
              <Col>{props.rm.hunting_district}</Col>
              <Col>{vetTestStatus}</Col>
              <Col>{new Date(props.rm.createdAt).toLocaleDateString()}</Col>
              <button className="stealth-button" onClick={localDownloadHandler}>
                <img
                  data-rid={props.rm.id}
                  className="pdf-icon my-auto mr-3"
                  src={PDF}
                  alt="pdf icon"
                />
              </button>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={props.rm.id}>
            <Card.Body>
              <div className="d-flex justify-content-end mb-4">
                {props.rm.vetId === undefined && (
                  <>
                    <label>Zuweisen</label>
                    <input
                      className="mt-2 ml-2"
                      type="checkbox"
                      name="edit-info"
                      id="edit-info"
                      onChange={() =>
                        setCheckedAnimation((prevMode) => !prevMode)
                      }
                    />
                  </>
                )}
              </div>
              <CSSTransition
                in={checkedAnimation}
                timeout={400}
                classNames="form-300"
                unmountOnExit
                onEnter={() => setChecked(true)}
                onExited={() => setChecked(false)}
              >
                <div>
                  {checked && (
                    <RawMaterialsEditForm
                      rid={props.rm.id}
                      assignCallback={assignCallback2}
                    />
                  )}
                </div>
              </CSSTransition>
              <div>
                <div className="row">
                  <div className="col-6">
                    <p className="supplier-card-list-p">Lieferant</p>
                    <p className="border p-2 rounded bg-light">
                      {props.rm.supplierName}
                    </p>
                  </div>
                  <div className="col-6">
                    <p className="supplier-card-list-p">Lieferanten ID</p>
                    <p className="border p-2 rounded bg-light">
                      {props.rm.supplier}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="supplier-card-list-p">Wildart</p>
                    <p className="border p-2 rounded bg-light">
                      {props.rm.type}
                    </p>
                  </div>
                  <div className="col-6">
                    <p className="supplier-card-list-p">Geschlecht</p>
                    <p className="border p-2 rounded bg-light">{gender}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p className="supplier-card-list-p">Gewicht</p>
                    <p className="border p-2 rounded bg-light">
                      {props.rm.weight / 1000 } kg
                    </p>
                  </div>
                  <div className="col-6">
                    <p className="supplier-card-list-p">Defekte</p>
                    <p className="border p-2 rounded bg-light">
                      {props.rm.defects
                        .map((d) => DEFECTS_OBJECT[d.name])
                        .join(", ")}
                    </p>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};

export default RawMaterialsCard;
