import moment from 'moment'

export const useReminder = () => {

  const getDueDate = (lastCheckedDate, repeat) => {
    switch (repeat) {
      case "dailyIncl":
        if (moment(lastCheckedDate).add(1, "days").day() === 0) {
          return moment(lastCheckedDate).add(2, "days");
        } else {
          return moment(lastCheckedDate).add(1, "days");
        }
      case "daily":
        if (moment(lastCheckedDate).add(1, "days").day() === 6) {
          return moment(lastCheckedDate).add(3, "days");
        }
        if (moment(lastCheckedDate).add(1, "days").day() === 0) {
          return moment(lastCheckedDate).add(2, "days");
        }
        return moment(lastCheckedDate).add(1, "days");
      case "weekly":
        return moment(lastCheckedDate).add(7, "days");
      case "monthly":
        return moment(lastCheckedDate).add(1, "months")
      case "manuel":
        return "Manuell"
      default:
        break;
    }
  }

  const getReminder = (lastCheckedDate, dueDate) => {
    if (!lastCheckedDate) {
      return "default"
    }
    if (dueDate === "Manuell") {
      return "Manuell";
    }
    if (
      dueDate < moment(new Date()).format("DD-MM-yyyy")
    ) {
      return "overdue"
    }

    if (moment(new Date()).format("DD-MM-yyyy") === dueDate) {
      return "today";
    }
    if (
      moment(lastCheckedDate).format("DD-MM-yyyy") ===
      moment(new Date()).format("DD-MM-yyyy")
    ) {
      return "checked";
    }
    return "default"
  };

  return { getDueDate, getReminder };
};

