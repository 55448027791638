import React from "react";

import Input from "../../shared/components/formElements/Input";
import Button from "../../shared/components/formElements/Button";
import {
  VALIDATOR_REQUIRE,
} from "../../shared/util/validators";

const SupplierForm = (props) => {
  return (
    <form action="" onSubmit={props.formSubmitHandler}>
      <div className="row">
        <Input
          col="col-6"
          id="supplier_name"
          element="input"
          type="text"
          label="Name"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Bitte geben Sie Vor- und Nachnamen ein."
          onInput={props.inputHandler}
        />
        <Input
          col="col-6"
          id="addr_line"
          element="input"
          type="text"
          label="Strasse, HNr"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Bitte geben Sie Strasse und Hausnummer ein."
          onInput={props.inputHandler}
        />
      </div>
      <div className="row">
        <Input
          col="col-6"
          id="postal_code"
          element="input"
          type="text"
          label="Postleitzahl"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Bitte geben Sie eine gültige Postleitzahl ein."
          onInput={props.inputHandler}
        />
        <Input
          col="col-6"
          id="city"
          element="input"
          type="text"
          label="Stadt"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Bitte geben Sie eine Stadt oder einen Ort ein."
          onInput={props.inputHandler}
        />
      </div>
      <div className="row">
        <Input
          col="col-6"
          id="tel"
          element="input"
          type="text"
          label="Tel"
          validators={[]}
          errorText="Bitte geben Sie eine gültige Telefonnummer ein."
          onInput={props.inputHandler}
          initialValid={true}
        />
        <Input
          col="col-6"
          id="email"
          element="input"
          type="email"
          label="Email"
          validators={[]}
          errorText="Bitte geben Sie eine gültige Email ein."
          onInput={props.inputHandler}
          initialValid={true}
        />
      </div>
      <div className="row">
        <Input
          col="col-6"
          id="iban"
          element="input"
          type="text"
          label="IBAN"
          validators={[]}
          initialValid={true}
          errorText="Bitte geben Sie eine gültige IBAN ein."
          onInput={props.inputHandler}
        />
        <Input
          col="col-6"
          id="bic"
          element="input"
          type="text"
          label="BIC"
          validators={[]}
          initialValid={true}
          errorText="Bitte geben Sie eine gültige BIC ein."
          onInput={props.inputHandler}
        />
      </div>
      <div className="row">
        <Input
          col="col-6"
          id="admin_district"
          element="input"
          type="text"
          label="Landkreis"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Bitte geben Sie mindestens einen Landkreis ein."
          onInput={props.inputHandler}
        />
        <Input
          col="col-6"
          id="hunting_district"
          element="input"
          type="text"
          label="Jagdbezirk"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Bitte geben Sie mindestens einen Jagdbezirk ein."
          onInput={props.inputHandler}
        />
      </div>
      <br />
      <Button type="submit" disabled={!props.formState.isValid} primary>
        Lieferanten anlegen
      </Button>
    </form>
  );
}

 export default SupplierForm;
