import React, { useState, useContext } from 'react'

import ErrorModal from '../../../shared/components/uiElements/ErrorModal'
import Modal from "../../../shared/components/uiElements/Modal";
import Button from "../../../shared/components/formElements/Button";
import Input from '../../../shared/components/formElements/Input';
import Select from '../../../shared/components/formElements/Select';
import RadioGroup from '../../../shared/components/formElements/RadioGroup';
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import { useForm } from "../../../shared/hooks/form-hook";
import { VALIDATOR_REQUIRE } from "../../../shared/util/validators";
import { REPEAT_ARRAY, REPEAT_OBJECT } from "../../../shared/util/converterObjects";

const CleaningStepCardActions = props => {
  const auth = useContext(AuthContext);
  const { error, sendRequest, clearError } = useHttpClient();
  const { id } = props.cleaningStep;
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [formState, inputHandler] = useForm(
    {
      kind: {
        value: props.cleaningStep.kind,
        isValid: true,
      },
      repeat: {
        value: props.cleaningStep.repeat,
        isValid: true,
      },
      area: {
        value: props.cleaningStep.area,
        isValid: true,
      },
      instruction: {
        value: props.cleaningStep.instruction,
        isValid: true,
      },
      cleaningAgent: {
        value: props.cleaningStep.cleaningAgent,
        isValid: true,
      },
      applicationTime: {
        value: props.cleaningStep.applicationTime,
        isValid: true,
      },
      temperature: {
        value: props.cleaningStep.temperature,
        isValid: true,
      },
      concentration: {
        value: props.cleaningStep.concentration,
        isValid: true,
      },
    },
    true
  );

  const deleteHandler = async () => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/rooms/${props.roomId}/cleaningstep`,
        "DELETE",
        {
          "Content-Type": "application/json",
          authorization: "Bearer " + auth.token,
        },
        JSON.stringify({
          cleaningStepId: id,
        })
      );
      props.deleteCallback(id);
      setShowDelete(false);
    } catch (error) {
      // handled by useHttpClient hook
    }
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/rooms/${props.roomId}/cleaningstep`,
        "PATCH",
        {
          "Content-Type": "application/json",
          authorization: "Bearer " + auth.token,
        },
        JSON.stringify({
          cleaningStepId: id,
          kind: formState.inputs.kind.value,
          repeat: formState.inputs.repeat.value.value,
          area: formState.inputs.area.value,
          instruction: formState.inputs.instruction.value,
          cleaningAgent: formState.inputs.cleaningAgent.value,
          applicationTime: formState.inputs.applicationTime.value,
          temperature: formState.inputs.temperature.value,
          concentration: formState.inputs.concentration.value
        })
      );
      setShowEdit(false);
      props.updateCallback(formState.inputs, id);
    } catch (error) {
      // handled by useHttpClient hook
    }
  }



  return (
    <div>
      <ErrorModal error={error} onClear={clearError} />
      <button className="stealth-button" onClick={() => setShowEdit(true)}>
        <i className="fas fa-pen m-3 text-secondary"></i>
      </button>
      <button className="stealth-button" onClick={() => setShowDelete(true)}>
        <i className="fas fa-trash m-3 text-secondary"></i>
      </button>
      <Modal
        onCancel={() => setShowDelete(false)}
        header="Sind Sie sicher?"
        show={showDelete}
        footer={
          <>
            <Button
              classes="btn btn-outline-danger mx-2"
              onClick={() => setShowDelete(false)}
            >
              Abbrechen
            </Button>
            <Button classes="btn btn-danger mx-2" onClick={deleteHandler}>
              Reinigungsschritt löschen
            </Button>
          </>
        }
      >
        <p>Diese Aktion kann nicht rückgängig gemacht werden.</p>
      </Modal>
      <Modal
        onSubmit={submitHandler}
        onCancel={() => setShowEdit(false)}
        header="Reinigungsschritt ändern"
        show={showEdit}
        footer={
          <>
            <Button classes="mx-2" onClick={() => setShowEdit(false)} outline>
              Abbrechen
            </Button>
            <Button
              form="modal-form"
              type="submit"
              classes="mx-2"
              onClick={() => {}}
              primary
            >
              Speichern
            </Button>
          </>
        }
      >
        <div className="px-5 text-left">
          <div className="row">
            <Select
              col="col-6"
              id="repeat"
              label="Wiederholung"
              onInput={inputHandler}
              validators={[]}
              options={REPEAT_ARRAY}
              initialValid={true}
              initialValue={{
                key: REPEAT_OBJECT[props.cleaningStep.repeat],
                value: props.cleaningStep.repeat,
              }}
            />
            <div className="col-6" style={{ marginTop: "2.4rem" }}>
              <RadioGroup
                name="kind"
                id="kind"
                items={[
                  { id: "kindClean", label: "Reinigung", value: "clean" },
                  {
                    id: "kindDisinfect",
                    label: "Desinfektion",
                    value: "disinfect",
                  },
                ]}
                inline
                onInput={inputHandler}
                initialValid={true}
                initialValue={props.cleaningStep.kind}
              />
            </div>
          </div>
          <Input
            id="area"
            element="input"
            type="text"
            label="Bereich"
            onInput={inputHandler}
            validators={[VALIDATOR_REQUIRE()]}
            initialValid={true}
            initialValue={props.cleaningStep.area}
          />
          <Input
            id="instruction"
            element="input"
            type="text"
            label="Anweisung"
            onInput={inputHandler}
            validators={[VALIDATOR_REQUIRE()]}
            initialValid={true}
            initialValue={props.cleaningStep.instruction}
          />
          <Input
            id="cleaningAgent"
            element="input"
            type="text"
            label="Reinigunsmittel"
            onInput={inputHandler}
            validators={[VALIDATOR_REQUIRE()]}
            initialValid={true}
            initialValue={props.cleaningStep.cleaningAgent}
          />
          <div className="row">
            <Input
              col="col-4"
              id="applicationTime"
              element="input"
              type="number"
              label="Einwirkzeit (in min)"
              onInput={inputHandler}
              validators={[]}
              initialValid={true}
              initialValue={props.cleaningStep.applicationTime}
            />
            <Input
              col="col-4"
              id="temperature"
              element="input"
              type="number"
              label="Temperatur (in °C)"
              onInput={inputHandler}
              validators={[]}
              initialValid={true}
              initialValue={props.cleaningStep.temperature}
            />
            <Input
              col="col-4"
              id="concentration"
              element="input"
              type="number"
              label="Konzentration (in %)"
              onInput={inputHandler}
              validators={[]}
              initialValid={true}
              initialValue={props.cleaningStep.concentration}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CleaningStepCardActions;
