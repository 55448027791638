import React, { useContext, useState } from 'react'
import { CSSTransition } from "react-transition-group";
import Alert from 'react-bootstrap/Alert';

import ErrorModal from '../../shared/components/uiElements/ErrorModal';
import Input from '../../shared/components/formElements/Input';
import SuccessButton from '../../shared/components/formElements/SuccessButton';
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { VALIDATOR_EMAIL } from '../../shared/util/validators';

import "../../css/Animation.css"
const ChangeEmail = props => {
  const auth = useContext(AuthContext);
  const { sendRequest, error, clearError } = useHttpClient();
  const [showInfo, setShowInfo] = useState(false);
  const [formState, inputHandler] = useForm({
    newEmail: {
      value: "",
      isValid: false
    }
  }, false)

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
       await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/users/requestnewemail",
          "POST",
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
          JSON.stringify({
            newEmail: formState.inputs.newEmail.value,
        }))
        setShowInfo(true);
    } catch (error) {}
  }

  return (
    <>
    <ErrorModal error={error} onClear={clearError} />
      <CSSTransition
        in={showInfo}
        timeout={400}
        classNames="form-150"
        unmountOnExit
      >
        <div>
          <Alert variant="warning" onClose={() => setShowInfo(false)} dismissible>
            <Alert.Heading>Info:</Alert.Heading>
            <p>Eine Bestätigungsemail wurde an die neue Adresse gesendet. <br/> Erst wenn Sie die neue Emailadresse bestätigt haben, wird diese aktiv.</p>
          </Alert>
        </div>
      </CSSTransition>
      <form onSubmit={submitHandler}>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="oldEmail" className="my-2">
              Aktuelle Email
            </label>
            <input
              id="oldEmail"
              type="text"
              value={props.oldEmail}
              className="form-control"
              readOnly
            />
          </div>
          <Input
            classes="bg-light"
            col="col-6"
            id="newEmail"
            label="Neue Email"
            element="input"
            type="text"
            onInput={inputHandler}
            validators={[VALIDATOR_EMAIL]}
            errorText="Bitte geben Sie eine gültige Emailadresse ein."
          />
        </div>
        <br />
        <SuccessButton width="200px" disabled={!formState.isValid}>Email Speichern</SuccessButton>
      </form>
    </>
  );
}

export default ChangeEmail;
