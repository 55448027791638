import React, { useState, useEffect } from "react";
import { DatePicker } from "@material-ui/pickers";

import { useInput } from "../../hooks/input-hook";

const MyDatePicker = (props) => {
  const { inputState, changeHandler } = useInput(props);
  const { id, onInput } = props;
  const { value, isValid } = inputState;
  const [selectedDate, handleDateChange] = useState(value);

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  const onChangeCallback = async event => {
    changeHandler(event)
    props.onAccept(id, event._d)
  }

  return (
    <DatePicker
      id={props.id}
      variant="inline"
      value={selectedDate}
      label={props.label}
      onChange={handleDateChange}
      onAccept={onChangeCallback}
    />
  );
};

export default MyDatePicker;
