import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";

import SupplierCard from "./SupplierCard";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const SupplierList = () => {
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const [supplierRes, setSupplierRes] = useState();
  const [term, setTerm] = useState("");

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const res = await sendRequest(process.env.REACT_APP_BACKEND_URL + "/suppliers", "GET", {authorization: "Bearer " + auth.token});
        setSupplierRes(res.suppliers);
      } catch (error) {}
    };
    fetchSuppliers();
  }, [sendRequest, auth.token]);

    const editCallback = (inputs, id) => {
      const sups = Object.assign([], supplierRes);
      const index = sups.findIndex(sup => sup.id === id);
      sups[index].supplier_name = inputs.supplier_name.value
      sups[index].addr_line = inputs.addr_line.value
      sups[index].postal_code = inputs.postal_code.value
      sups[index].city = inputs.city.value
      sups[index].tel = inputs.tel.value
      sups[index].iban = inputs.iban.value
      sups[index].bic = inputs.bic.value
      sups[index].email = inputs.email.value
      sups[index].hunting_district = inputs.hunting_district.value
      sups[index].admin_district = inputs.admin_district.value

      setSupplierRes(sups);
    };

  if (supplierRes) {
    const filterResult = supplierRes.filter((sR) => {
      return sR.supplier_name.toLowerCase().includes(term.toLowerCase());
    });
    return (
      <div>
        <div className="d-flex">
          <div className="card mb-3 p-3 w-100">
            <input
              type="text"
              placeholder="Filter"
              onChange={(e) => setTerm(e.target.value)}
            />
          </div>
          <div className="supplier-new-btn mt-2 ml-2">
            <Link to="./suppliernew">
              <Button variant="primary" size="lg">
                +
              </Button>
            </Link>
          </div>
        </div>
        {filterResult.map((supplier) => (
          <div key={supplier.id}>
            <SupplierCard sup={supplier} editCallback={editCallback}/>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="box">
      <div className="d-flex justify-content-center loading-screen">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    </div>
  );
};

export default SupplierList;
