import React, { useEffect, useContext, useState } from 'react'
import Spinner from "react-bootstrap/Spinner";

import ErrorModal from '../../shared/components/uiElements/ErrorModal';
import RoomCardHygene from '../components/roomIndex/RoomCardHygene';
import RoomsListTabs from '../components/roomIndex/RoomsListTabs';
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const RoomsListHygene = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [roomsRes, setRoomsRes] = useState();

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/rooms",
          "GET",
          { Authorization: "Bearer " + auth.token }
        );
        setRoomsRes(res.rooms);
      } catch (error) {}
    };
    fetchRooms();
  }, [auth.token, sendRequest]);

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <RoomsListTabs isHygene />
      <div className="container mt-4">
        <div className="row">
          {isLoading && (
            <div className="d-flex justify-content-center loading-screen">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}
          {roomsRes &&
            roomsRes.map((room) => (
              <RoomCardHygene room={room} key={room.id} />
            ))}
        </div>
      </div>
    </>
  );
};

export default RoomsListHygene
