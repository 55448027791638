import React from "react";
import RawMaterialsList from "../components/RawMaterialsList";
import RawMaterialsTabs from '../components/RawMaterialsTabs'

const RawMaterials = () => {
  return (
    <div>
      <RawMaterialsTabs isIndex />
      <p className="h2">Übersicht Lieferungen</p>
      <RawMaterialsList />
    </div>
  );
};

export default RawMaterials;
