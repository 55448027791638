import React from "react";
import {
  Switch,
  Route,
} from "react-router-dom";

import Home from "../navigation/pages/Home";
import Dashboard from "../dashboard/pages/Dashboard";
import Supplier from "../suppliers/pages/Supplier";
import NewSupplier from "../suppliers/pages/NewSupplier";
import RawMaterials from "../raw_materials/pages/RawMaterials";
import RawMaterialsNew from "../raw_materials/pages/RawMaterialsNew";
import Stock from "../stock/pages/Stock";
import Customers from "../customers/pages/Customers";
import CustomerNew from "../customers/pages/CustomerNew";
import Orders from "../orders/pages/Orders";
import OrderNew from "../orders/pages/OrderNew";
import Shipments from "../shipments/pages/Shipments";
import Analysis from "../analysis/pages/Analysis";
import TeamSettings from "../users/pages/TeamSettings";
import ProfileSettings from "../users/pages/ProfileSettings";
import PartnerSettings from "../users/pages/PartnerSettings";
import RoomHygene from "../rooms/pages/RoomHygene";
import RoomHygeneSettings from "../rooms/pages/RoomHygeneSettings";
import RoomCoolingSettings from "../rooms/pages/RoomCoolingSettings";
import RoomsListCooling from "../rooms/pages/RoomsListCooling";
import RoomsListHygene from "../rooms/pages/RoomsListHygene";
import RoomsListSettings from "../rooms/pages/RoomsListSettings";
import RoomCooling from "../rooms/pages/RoomCooling";

const OwnerRoutes = () => {
 return (
   <Switch>
     <Route path="/" exact component={Home} />
     <Route path="/dashboard" component={Dashboard} />
     <Route path="/supplier/supplier" component={Supplier} />
     <Route path="/supplier/suppliernew" component={NewSupplier} />
     <Route path="/raw_materials/rawmaterials" component={RawMaterials} />
     <Route path="/raw_materials/rawmaterialsnew" component={RawMaterialsNew} />
     <Route path="/stock" component={Stock} />
     <Route path="/customers/customers" component={Customers} />
     <Route path="/customers/customernew" component={CustomerNew} />
     <Route path="/order/orders" component={Orders} />
     <Route path="/order/ordernew" component={OrderNew} />
     <Route path="/shipments" component={Shipments} />
     <Route path="/analysis" component={Analysis} />
     <Route path="/TeamSettings" component={TeamSettings} />
     <Route path="/profilesettings" component={ProfileSettings} />
     <Route path="/partnersettings" component={PartnerSettings} />
     <Route path="/rooms/cooling" component={RoomsListCooling} />
     <Route path="/rooms/hygene" component={RoomsListHygene} />
     <Route path="/rooms/settings" component={RoomsListSettings} />
     <Route path="/:roomId/hygene" component={RoomHygene} exact/>
     <Route path="/:roomId/hygene/settings" component={RoomHygeneSettings} exact/>
     <Route path="/:roomId/cooling/settings" component={RoomCoolingSettings} exact/>
     <Route path="/:roomId/cooling" component={RoomCooling} exact/>
    <Route component={Home} />
   </Switch>
 );
}

export default OwnerRoutes;
