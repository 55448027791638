import React, { useState, useContext, useCallback } from "react";

import Select from "../../shared/components/formElements/Select";
import Input from "../../shared/components/formElements/Input";
import Checkbox from "../../shared/components/formElements/Checkbox";
import RadioGroup from "../../shared/components/formElements/RadioGroup";
import ErrorModal from "../../shared/components/uiElements/ErrorModal";
import SuccessButton from "../../shared/components/formElements/SuccessButton";
import SignaturePad from "../../shared/components/formElements/SignaturePad";
import { useForm } from "../../shared/hooks/form-hook";
import { useSuccess } from "../../shared/hooks/success-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";

import "../../css/CustomInputs.css";
import "../../css/SettingsBtn.css";

const VetTestForm = (props) => {
  const { error, sendRequest, clearError } = useHttpClient();
  const { loading, success, onStart, onSuccess, onError } = useSuccess();
  const auth = useContext(AuthContext);
  const [isSigning, setIsSigning] = useState(false);
  const [formState, inputHandler] = useForm(
    {
      testMethod: {
        value: "",
        isValid: false,
      },
      approved: {
        value: "",
        isValid: true,
      },
      vetSignature: {
        value: "",
        isValid: false,
      },
      reasonForDenial: {
        value: "",
        isValid: true
      }
    },
    false
  );


  const modalOpenHandler = () => {
    setIsSigning(true);
  };

  const modalCloseHandler = () => {
    setIsSigning(false);
  };
  const onRadioInput = useCallback(
    (id, value, isValid) => {
      if (value !== "") {
        inputHandler("approved", value, isValid);
      }
    },
    [inputHandler]
  );

  const submitHandler = async (event) => {
    event.preventDefault();
    console.log(formState.inputs);
    try {
      onStart();
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/rawmaterials/test/${props.id}`,
        "PATCH",
        {
          "Content-Type": "application/json",
          authorization: "Bearer " + auth.token,
        },
        JSON.stringify({
          testMethod: formState.inputs.testMethod.value,
          vetSignature: formState.inputs.vetSignature.value,
          approved: formState.inputs.approved.value === "false" ? false : true,
          reasonForDenial: formState.inputs.reasonForDenial.value
        })
        );
        onSuccess();
        if (props.onSuccess) {
          props.onSuccess(formState.inputs, props.id);
        }
    } catch (err) {
      onError();
      // handled by useHttpClient hook
    }
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <form onSubmit={submitHandler}>
        <div className="mb-3">
          <RadioGroup
            name="forVet"
            id={`approved-${props.id}`}
            items={[
              {
                id: `approved-${props.id}-approve`,
                label: "Freigeben",
                value: true,
              },
              {
                id: `approved-${props.id}-deny`,
                label: "Nicht freigeben",
                value: false,
              },
            ]}
            onInput={onRadioInput}
            inline
          />
        </div>
        { formState.inputs.approved.value === "false" &&
          <div className="row">
            <Input
              col="col-6"
              id="reasonForDenial"
              element="input"
              type="text"
              label="Grund für die Nicht-Freigabe."
              onInput={inputHandler}
              validators={[]}
              initialValid={true}
            />
          </div>
        }
        <div className="row">
          <Select
            classes="custom-select mb-0"
            col="col-6"
            id="testMethod"
            label="Test Methode"
            onInput={inputHandler}
            options={[
              { key: "Sichtuntersuchung", value: "Sichtuntersuchung" },
              { key: "Referenzverfahren", value: "Referenzverfahren" },
              { key: "Trichomatic", value: "Trichomatic" },
            ]}
            validators={[]}
          />
          <div style={{ position: "relative" }}>
            {formState.inputs.vetSignature.isValid &&
              formState.inputs.approved.value && (
                <SuccessButton
                  classes="rsp-btn"
                  isLoading={loading}
                  success={success}
                  disabled={!formState.isValid}
                  width="150px"
                  style={{
                    position: "absolute",
                    bottom: "0px",
                    height: "37px",
                  }}
                >
                  Speichern
                </SuccessButton>
              )}
            {formState.inputs.vetSignature.isValid &&
              !formState.inputs.approved.value && (
                <SuccessButton
                  isLoading={loading}
                  success={success}
                  disabled={!formState.isValid}
                  width="150px"
                  classes="btn-danger rsp-btn"
                  style={{
                    position: "absolute",
                    bottom: "0px",
                    height: "37px",
                  }}
                >
                  Ablehnen
                </SuccessButton>
              )}
            {!formState.inputs.vetSignature.isValid && (
              <SuccessButton
                classes="rsp-btn"
                width="150px"
                type="button"
                onClick={modalOpenHandler}
                primary
                style={{ position: "absolute", bottom: "0px", height: "37px" }}
              >
                Unterschreiben
              </SuccessButton>
            )}
          </div>
        </div>
      </form>
      <SignaturePad
        id="vetSignature"
        show={isSigning}
        onClear={modalCloseHandler}
        onInput={inputHandler}
      />
    </React.Fragment>
  );
};

export default VetTestForm;
