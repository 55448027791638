import React from "react";
import ReactDom from "react-dom";
import { Link } from "react-router-dom";

import "../../../css/Tabs.css";

const RoomsListTabs = (props) => {
  const content = (
    <div className="border-top">
      <div className="container">
        <Link to={props.overviewLink}>
          <button className={`stealth-button tab`}>
            <i className="fas fa-arrow-left" style={{ fontSize: "1rem" }}></i>{" "}
            ZURÜCK ZUR ÜBERSICHT
          </button>
        </Link>
        <Link to={`/${props.roomId}/${props.url}`}>
          <button
            className={`stealth-button tab ${props.isDash ? "tab-active" : ""}`}
          >
            DASHBOARD
          </button>
        </Link>
        <Link to={`/${props.roomId}/${props.url}/settings`}>
          <button
            className={`stealth-button tab ${
              props.isSettings ? "tab-active" : ""
            }`}
          >
            EINSTELLUNGEN
          </button>
        </Link>
      </div>
    </div>
  );
  return ReactDom.createPortal(content, document.getElementById("tab-hook"));
};

export default RoomsListTabs;
