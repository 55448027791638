import { useReducer } from "react";
import { validate } from "../util/validators";

const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    case "RESET":
      return {
        value: action.val,
        isValid: action.isValid,
      };
    default:
      return state;
  }
};

export const useInput = (props) => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.initialValue || "",
    isValid: props.initialValid || false,
    isTouched: false,
  });

  const changeHandler = (event) => {
    dispatch({
      type: "CHANGE",
      val: event._d || event.target.value,
      validators: props.validators,
    });
    if (props.onSelect) props.onSelect(event);
  };

  const resetHandler = (resetValue, resetValid) => {
    dispatch({
      type: "RESET",
      val: resetValue,
      isValid: resetValid || false,
    });
  }

  const touchHandler = () => {
    dispatch({
      type: "TOUCH",
    });
  };

  return {inputState, changeHandler, touchHandler, resetHandler}

}
