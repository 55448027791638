import React from "react";
import ReactDom from "react-dom";
import Backdrop from "./Backdrop";
import { CSSTransition } from "react-transition-group";

import "./Modal.css";

const ModalOverlay = (props) => {
  const content = (
    <div className={`__modal ${props.className}`} style={props.style} data-testid="modal" role="dialog">
      <header className={`__modal__header ${props.headerClass}`}>
        <h2>{props.header}</h2>
      </header>
      <form id="modal-form"
        onSubmit={
          props.onSubmit ? props.onSubmit : (event) => event.preventDefault()
        }
      >
        <div className={`__modal__content ${props.contentClass}`}>
          {props.children}
        </div>
      </form>
        <footer className={`__modal__footer ${props.footerClass}`}>
          {props.footer}
        </footer>
    </div>
  );
  return ReactDom.createPortal(content, document.getElementById("modal-hook"));
};

const Modal = (props) => {
  return (
    <React.Fragment>
      {props.show && <Backdrop onClick={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="__modal"
      >
        <ModalOverlay {...props} />
      </CSSTransition>
    </React.Fragment>
  );
};

export default Modal;
