import React, { useContext } from "react";
import Card from "react-bootstrap/Card";

import ErrorModal from "../../shared/components/uiElements/ErrorModal";
import Input from "../../shared/components/formElements/Input";
import SuccessButton from "../../shared/components/formElements/SuccessButton";
import Select from "../../shared/components/formElements/Select";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_REQUIRE,
} from "../../shared/util/validators";

import "./CreateUser.css";

const CreateUser = (props) => {
  const auth = useContext(AuthContext);
  const { error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm(
    {
      firstName: {
        value: "",
        isValid: false,
      },
      lastName: {
        value: "",
        isValid: false,
      },
      email: {
        value: "",
        isValid: false,
      },
      role: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      const formValues = {
        firstName: formState.inputs.firstName.value,
        lastName: formState.inputs.lastName.value,
        email: formState.inputs.email.value,
        role: formState.inputs.role.value,
      };
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/users/createuser",
        "POST",
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        JSON.stringify(formValues)
      );
      props.addUserCallback({
        ...formValues,
        id: null,
        status: "pending",
        createdAt: new Date(Date.now()),
      });
    } catch (error) {
      // handled by useHttpClient hook
    }
  };

  let options;
  let initialValue;
  if (auth.userRole === "vetOfficial") {
    options = [{key: "Admin", value: "vetOfficial"}]
    initialValue = { key: "Admin", value: "vetOfficial" };
  } else {
    options = [
      { key: "Basic", value: "basic" },
      { key: "Admin", value: "admin" },
    ];
    initialValue = { value: "default", key: "role" };
  }

  return (
    <div>
      <ErrorModal error={error} onClear={clearError} />
      <Card className="p-4 pt-2">
        <form onSubmit={submitHandler}>
          <div className="row justify-content-between align-items-center">
            <Select
              col="col-md-6 col-lg-2 col-xl-2 align-self-center"
              classes="bg-light mr-1 mb-0"
              id="role"
              onInput={inputHandler}
              options={options}
              validators={[]}
              initialValue={initialValue}
            />
            <Input
              col="col-md-6 col-lg-3 col-xl-3 align-self-center"
              classes="bg-light mr-1 mb-0"
              id="firstName"
              placeholder="Vorname"
              element="input"
              onInput={inputHandler}
              type="text"
              validators={[VALIDATOR_REQUIRE()]}
            />
            <Input
              col="col-md-6 col-lg-3 col-xl-3 align-self-center"
              classes="bg-light mr-1 mb-0"
              id="lastName"
              placeholder="Nachname"
              element="input"
              onInput={inputHandler}
              type="text"
              validators={[VALIDATOR_REQUIRE()]}
            />
            <Input
              col="col-md-6 col-lg-3 col-xl-3 align-self-center"
              classes="bg-light mr-1 mb-0"
              id="email"
              element="input"
              type="text"
              placeholder="Email"
              validators={[VALIDATOR_EMAIL()]}
              onInput={inputHandler}
            />
            <div className="col-lg-1 col-xl-1 form-group">
              <SuccessButton classes="ml-4s form-control" primary>
                <span className="btn-symbol">
                  <i className="far fa-check-circle mt-1"></i>
                </span>
                <span className="btn-text"> Nutzer anlegen</span>
              </SuccessButton>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default CreateUser;
