import React, { useContext } from "react";

import ErrorModal from "../../shared/components/uiElements/ErrorModal";
import Input from "../../shared/components/formElements/Input";
import SuccessButton from "../../shared/components/formElements/SuccessButton";
import { useSuccess } from "../../shared/hooks/success-hook";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { VALIDATOR_REQUIRE } from "../../shared/util/validators";

const ChangeOrgInfo = props => {
  const auth = useContext(AuthContext);
  const { loading, success, onStart, onSuccess, onError } = useSuccess();
  const { error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm(
    {
      companyName: {
        value: props.company.companyName,
        isValid: true,
      },
      tel: {
        value: props.company.tel,
        isValid: true,
      },
      postal_code: {
        value: props.company.postal_code,
        isValid: true,
      },
      city: {
        value: props.company.city,
        isValid: true,
      },
      addr_line: {
        value: props.company.addr_line,
        isValid: true,
      },
    },
    true
  );

  const submitHandler = async (event) => {
    event.preventDefault();
    console.log(formState.inputs);
     try {
       onStart();
       await sendRequest(
         process.env.REACT_APP_BACKEND_URL + "/companies/company",
         "PATCH",
         {
           "Content-Type": "application/json",
           Authorization: "Bearer " + auth.token,
         },
         JSON.stringify({
           companyName: formState.inputs.companyName.value,
           tel: formState.inputs.tel.value,
           postal_code: formState.inputs.postal_code.value,
           city: formState.inputs.city.value,
           addr_line: formState.inputs.addr_line.value,
         })
       );
       onSuccess();
     } catch (error) {
       onError();
     }
  }


  return (
    <>
    <ErrorModal error={error} onClear={clearError} />
      <form onSubmit={submitHandler}>
        <Input
          id="companyName"
          element="input"
          type="text"
          label="Firmenname"
          onInput={inputHandler}
          validators={[VALIDATOR_REQUIRE()]}
          initialValid={true}
          initialValue={props.company.companyName}
        />
        <Input
          id="tel"
          element="input"
          type="text"
          label="Telefonnummer"
          onInput={inputHandler}
          validators={[]}
          initialValid={true}
          initialValue={props.company.tel}
        />
        <div className="row">
          <Input
            col="col-6"
            id="postal_code"
            element="input"
            type="text"
            label="Postleitzahl"
            onInput={inputHandler}
            validators={[VALIDATOR_REQUIRE()]}
            initialValid={true}
            initialValue={props.company.postal_code}
          />
          <Input
            col="col-6"
            id="city"
            element="input"
            type="text"
            label="Stadt"
            onInput={inputHandler}
            validators={[VALIDATOR_REQUIRE()]}
            initialValid={true}
            initialValue={props.company.city}
          />
        </div>
        <Input
          id="addr_line"
          element="input"
          type="text"
          label="Strasse, Hausnummer"
          onInput={inputHandler}
          validators={[VALIDATOR_REQUIRE()]}
          initialValid={true}
          initialValue={props.company.addr_line.split(" ").join(", ")}
        />
        <br/>
        <SuccessButton isLoading={loading} success={success} width="220px" height="40px">
          Änderungen speichern
        </SuccessButton>
      </form>
    </>
  );
};

export default ChangeOrgInfo;
