import React, { useContext, useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

import ErrorModal from "../../shared/components/uiElements/ErrorModal";
import UserCard from "./UserCard";
import UserHeaderCard from "./UserHeaderCard";
import CreateUser from "./CreateUser";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

import "../../css/Animation.css"

const UserList = (props) => {
  const auth = useContext(AuthContext);
  const [loadedUsers, setLoadedUsers] = useState(null);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showCreate, setShowCreate] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      let res;
      try {
        res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/users",
          "GET",
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        setLoadedUsers(res.users);
      } catch (error) {
        // handled by useHttpClient
      }
    };
    fetchUsers();
  }, [auth.token, sendRequest]);

  const userActionsCallback = (action, inputs, id) => {

      let updatedUsers = Object.assign([], loadedUsers);
      const index = loadedUsers.findIndex((element) => element.id === id);

      switch (action) {
        case "edit":
          updatedUsers[index].firstName = inputs.firstName.value;
          updatedUsers[index].lastName = inputs.lastName.value;
          updatedUsers[index].role = inputs.role.value;
          updatedUsers[index].email = inputs.email.value;
          break;
        case "delete":
          updatedUsers.splice(index, 1);
          break;
        case "resend":
          updatedUsers[index].createdAt = new Date(Date.now());
          break;
        case "add":
          updatedUsers.push("new item")
          break;
        default:
          break;
      }
      setLoadedUsers(updatedUsers);
  }

  const addUserCallback = (newUser) => {
    let updatedUsers = Object.assign([], loadedUsers);
    updatedUsers.push(newUser)
    setLoadedUsers(updatedUsers);
    setShowCreate(false);
  }

    return (
      <>
        <ErrorModal error={error} onClear={clearError} />
        <UserHeaderCard />
        {loadedUsers && (
          <div>
            {loadedUsers.map((user) => (
              <UserCard
                userData={user}
                key={user.id}
                userActionsCallback={userActionsCallback}
              />
            ))}
            <CSSTransition
              in={showCreate}
              timeout={400}
              classNames="form-300"
              unmountOnExit
            >
              <CreateUser addUserCallback={addUserCallback} />
            </CSSTransition>
            <Button
              onClick={() => setShowCreate((prevMode) => !prevMode)}
              variant="primary"
              size="lg"
            >
              +
            </Button>
          </div>
        )}

        {isLoading && (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
      </>
    );
};

export default UserList;
