import React, { useContext } from "react";

import ErrorModal from '../../shared/components/uiElements/ErrorModal'
import RadioGroup from '../../shared/components/formElements/RadioGroup';
import Input from "../../shared/components/formElements/Input";
import SuccessButton from "../../shared/components/formElements/SuccessButton";
import { AuthContext } from "../../shared/context/auth-context";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import {
  VALIDATOR_REQUIRE,
} from "../../shared/util/validators";

const RawMaterialsEditForm = (props) => {
  const auth = useContext(AuthContext);
  const { error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm(
    {
      forVet: {
        value: true,
        isValid: false,
      }
    },
    false
  );

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/rawmaterials/${props.rid}`,
        "PATCH",
        {
          "Content-Type": "application/json",
          authorization: "Bearer " + auth.token
        },
        JSON.stringify({
          forVet: formState.inputs.forVet.value,
        })
      );
      props.assignCallback(formState.inputs, props.rid)
    } catch (err) {
      // handled by useHttpClient hook
    }
  };

  return (
    <>
    <ErrorModal error={error} onClear={clearError} />
    <form onSubmit={formSubmitHandler}>
      <div className="row justify-content-around">
        <div className="row justify-content-start bg-light p-5 rounded">
          <RadioGroup
            name="forVet"
            id="forVet"
            items={[
              {
                id: "forVetAssign",
                label: "Dem hinterlegten Veterinär zuweisen.",
                value: "assignVet",
              },
              {
                id: "forVetNotAssign",
                label: "Keinen Veterinär zuweisen.",
                value: "assignNoVet",
              },
            ]}
            onInput={inputHandler}
          />
          <div>
            <SuccessButton width="150px" classes="mt-3 ml-3">Zuweisen</SuccessButton>
          </div>
        </div>
      </div>
      <br />
      <hr />
    </form>
    </>
  );
};

export default RawMaterialsEditForm;
