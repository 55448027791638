import React from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

import Input from "../../shared/components/formElements/Input";
import SuccessButton from "../../shared/components/formElements/SuccessButton";
import ErrorModal from "../../shared/components/uiElements/ErrorModal";
import { VALIDATOR_MINLENGTH } from '../../shared/util/validators';
import { useHttpClient } from "../../shared/hooks/http-hook";

const ResetPassword = props => {
  const history = useHistory();
  const { error, sendRequest, clearError } = useHttpClient();
  const { resetToken } = queryString.parse(props.location.search);

  const submitHandler = async event => {
    event.preventDefault();
    const password = document.getElementById("newPassword").value
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/users/reset",
        "POST",
        {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + resetToken,
        },
        JSON.stringify({ password })
      );
      history.push("/auth");
    } catch (error) {
      // handled by useHttpClient hook
    }
  }

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <form onSubmit={submitHandler}>
        <Input
          id="newPassword"
          label="Neues Passwort"
          element="input"
          type="password"
          onInput={() => {}}
          validators={[VALIDATOR_MINLENGTH(6)]}
          errorText="Das Passwort muss mindestens 6 Zeichen lang sein."
        />
        <Input
          id="confirmPassword"
          label="Passwort bestätigen"
          element="input"
          type="password"
          onInput={() => {}}
          validators={[VALIDATOR_MINLENGTH(6)]}
          errorText="Das Passwort muss mindestens 6 Zeichen lang sein."
        />
        <SuccessButton>Passwort bestätigen</SuccessButton>
      </form>
    </>
  );
};

export default ResetPassword;
