import React, { useEffect } from "react";
import Form from "react-bootstrap/Form"

import { useInput } from "../../hooks/input-hook";

const Select = props => {
  const { inputState, changeHandler, resetHandler } = useInput(props);
  const { id, onInput, onSelect } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  useEffect(() => {
    if (props.reset) {
      if (props.resetValue) {
        resetHandler(props.resetValue, props.resetValid);
        document.getElementById(props.id).value = props.resetVal;
      } else {
        resetHandler("", props.resetValid);
        document.getElementById(props.id).value = "default";
      }
    }
  }, [props.reset, props.id, props.resetVal, props.resetValue, props.resetValid, resetHandler]);

  return (
    <React.Fragment>
      <div className={props.col}>
        <Form.Group>
          {props.label && (
            <Form.Label className={` my-2`}>
              {props.label}
            </Form.Label>
          )}
          <Form.Control
            className={props.classes}
            data-testid="select"
            onSelect={onSelect}
            onChange={changeHandler}
            id={props.id}
            as="select"
            defaultValue="Bitte auswählen"
          >
            {!props.initialValue && (
              <option value="default">Bitte auswählen</option>
            )}
            {props.initialValue && (
              <option value={props.initialValue.value}>
                {props.initialValue.key}
              </option>
            )}
            {props.options.map((option) => (
              <option key={option.value} value={option.value}>
                {" "}
                {option.key}{" "}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </div>
    </React.Fragment>
  );
}

export default Select;
