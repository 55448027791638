import React, { useRef, useState } from 'react'

import SuccessButton from './SuccessButton';

const FileUpload = (props) => {
  const [file, setFile] = useState();
  const [previewTitle, setPreviewTitle] = useState();
  const [isValid, setIsValid] = useState(false);

  const filePickerRef = useRef();
  const pickFileHandler = () => {
    filePickerRef.current.click();
  }

  const changeHandler = event => {
    let file;
    let fileIsValid;
    if (event.target.files && event.target.files.length === 1) {
      file = event.target.files[0];
      fileIsValid = true;
      setPreviewTitle(file.name);
      setFile(file);
      setIsValid(true);
    } else {
      fileIsValid = false;
      setIsValid(false);
    }
    props.onInput(props.id, file, fileIsValid);
  }


  return (
    <>
      <input
        id={props.id}
        ref={filePickerRef}
        style={{ display: "none" }}
        type="file"
        accept=".csv"
        onChange={changeHandler}
      />
      <div className={`file-upload ${props.center && "text-center"}`}>
        <SuccessButton outline type="button" onClick={pickFileHandler}>
          Datei auswählen
        </SuccessButton>
        <br />
        {previewTitle && (
          <small>
            <i className="fas fa-check-circle" style={{ fontSize: "0.7rem" }}></i>
            {" "}
            {previewTitle}
          </small>
        )}
      </div>
    </>
  );
}

export default FileUpload;
