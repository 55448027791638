import React, { useRef, useReducer, useEffect } from "react";

import Modal from "../uiElements/Modal";
import SignatureCanvas from "react-signature-canvas";
import Button from "./Button";

const inputReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: action.val !== "",
      };
    default:
      return state;
  }
};

const SignaturePad = (props) => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: "",
    isValid: false,
  });
  const { id, onInput } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  const sigCanvas = useRef({});
  const clearHandler = () => sigCanvas.current.clear();
  const saveHandler = () => {
    dispatch({
      type: "CHANGE",
      val: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
    });
    props.onClear();
  };

  return (
    <Modal

      show={props.show}
      header="Please Sign Here"
      footer={
        <React.Fragment>
          <Button onClick={props.onClear} outline>
            Close
          </Button>
          <Button onClick={clearHandler} outline>
            Clear
          </Button>
          <Button onClick={saveHandler} primary>
            Save
          </Button>
        </React.Fragment>
      }
    >
      <SignatureCanvas
        penColor="black"
        canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        ref={sigCanvas}
      />
    </Modal>
  );
};

export default SignaturePad;
