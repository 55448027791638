import React from "react";

const Analysis = () => {
  return (
    <div>
      <h1>Analysis</h1>
    </div>
  );
};

export default Analysis;
