import React from "react";

import RawMaterialsList from "../components/RawMaterialsList";

const VetTestRawMaterial = () => {
  return (
    <div className="vh-100">
      <RawMaterialsList />
    </div>
  );
};

export default VetTestRawMaterial;
