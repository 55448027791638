import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import VetTestRawMaterial from "../raw_materials/pages/VetTestRawMaterial";
import VetSettings from '../users/pages/VetSettings'

const OwnerRoutes = () => {
  return (
    <Switch>
      <Route
        path="/rawmaterials/test"
        exact
        component={VetTestRawMaterial}
      />
      <Route
        path="/vetsettings"
        exact
        component={VetSettings}
      />
      <Redirect to="/rawmaterials/test" />
    </Switch>
  );
};

export default OwnerRoutes;
