import React, { useState, useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from 'moment'

import ErrorModal from '../../../shared/components/uiElements/ErrorModal';
import Modal from "../../../shared/components/uiElements/Modal";
import Button from "../../../shared/components/formElements/Button";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { REPEAT_OBJECT } from "../../../shared/util/converterObjects";
import { AuthContext } from "../../../shared/context/auth-context";

const CleaningStepDashCardActions = (props) => {
  const auth = useContext(AuthContext);
  const cs = props.cleaningStep;
  const { error, sendRequest, clearError } = useHttpClient();
  const [showCheck, setShowCheck] = useState(false);

  const checkHandler = async () => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/cleaningchecks`,
        "POST",
        {
          "Content-Type": "application/json",
          authorization: "Bearer " + auth.token,
        },
        JSON.stringify({
          cleaningStepId: props.cleaningStepId,
          roomId: props.roomId,
        })
      );
      setShowCheck(false);
      props.cleaningCheckCallback(props.cleaningStepId);
    } catch (error) {
      // handled by useHttpClient hook
    }
  };

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <button className="stealth-button" onClick={() => setShowCheck(true)}>
        <i className="fas fa-check-square text-secondary"></i>
      </button>
      <Modal
        onCancel={() => setShowCheck(false)}
        header="Reinigungsschritt durchgeführt"
        show={showCheck}
        footer={
          <>
            <Button
              classes="btn btn-outline-primary mx-2"
              onClick={() => setShowCheck(false)}
            >
              Abbrechen
            </Button>
            <Button classes="btn btn-primary mx-2" onClick={checkHandler}>
              durchgeführt
            </Button>
          </>
        }
      >
        <br />
        <Row className="text-muted">
          <Col>Zuletzt durchgeführt</Col>
          <Col>Reinigung / Desinfektion</Col>
          <Col>Fällig</Col>
        </Row>
        <Row>
          <Col>
            <strong>{moment(cs.lastCheckedAt).format("DD-MM-YYYY")}</strong>
          </Col>
          <Col>
            <strong>{REPEAT_OBJECT[cs.repeat]}</strong>
          </Col>
          <Col>
            <strong>{props.dueDate}</strong>
          </Col>
        </Row>
        <br />
        <Row className="text-muted">
          <Col>Bereich</Col>
          <Col>Reinigung / Desinfektion</Col>
          <Col>Anweisung</Col>
        </Row>
        <Row>
          <Col>
            <strong>{cs.area}</strong>
          </Col>
          <Col>
            <strong>{cs.kind}</strong>
          </Col>
          <Col>
            <strong>{cs.instruction}</strong>
          </Col>
        </Row>
        <br />
        <Row className="text-muted">
          <Col>Reinigunsmittel</Col>
          <Col>Einwirkzeit / Temperatur</Col>
          <Col>Konzentration</Col>
        </Row>
        <Row>
          <Col>
            <strong>{cs.cleaningAgent}</strong>
          </Col>
          <Col>
            {}
            <strong>{cs.applicationTime ? cs.applicationTime : "-"}</strong> min
            / <strong>{cs.temperature ? cs.temperature : "-"}</strong> °C
          </Col>
          <Col>
            <strong>{cs.concentration ? cs.concentration + "%" : "---"}</strong>
          </Col>
        </Row>
        <br />
        <hr />
      </Modal>
    </>
  );
};

export default CleaningStepDashCardActions;
