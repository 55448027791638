export const DEFECTS_OBJECT = {
  legLeft: "Keule (links)",
  legRight: "Keule (rechts)",
  backFront: "Rücken (vorne)",
  backBack: "Rücken (hinten)",
  dirtLight: "Schmutz (leicht)",
  dirtStrong: "Schmutz (stark)",
  rutting: "Rauschig / Brunftig",
};

export const REPEAT_ARRAY = [
  { key: "Täglich incl. Samstag", value: "dailyIncl" },
  { key: "Täglich excl. Samstag", value: "daily" },
  { key: "Wöchentlich", value: "weekly" },
  { key: "Monatlich", value: "monthly" },
  { key: "Manuell", value: "manuel" },
];

export const REPEAT_OBJECT = {
  dailyIncl: "Täglich incl. Samstag",
  daily: "Täglich excl. Samstag",
  weekly: "Wöchentlich",
  monthly: "Monatlich",
  manuel: "Manuell",
};

