import React, { useContext, useEffect } from 'react'

import ErrorModal from '../../shared/components/uiElements/ErrorModal'
import Input from '../../shared/components/formElements/Input';
import SuccessButton from '../../shared/components/formElements/SuccessButton';
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { VALIDATOR_REQUIRE } from '../../shared/util/validators';
import { useSuccess } from "../../shared/hooks/success-hook";
import { AuthContext } from "../../shared/context/auth-context";

const AddInfo = () => {
  const auth = useContext(AuthContext);
  const { loading, success, onStart, onSuccess, onError } = useSuccess();
  const { error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm(
    {
      firstName: {
        value: "",
        isValid: false,
      },
      lastName: {
        value: "",
        isValid: false,
      },
      city: {
        value: "",
        isValid: false,
      },
      postalCode: {
        value: "",
        isValid: false,
      },
      addrLine: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  useEffect(() => {
    const fetchUserInfo = async () => {
      let res;
      try {
        res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/users",
          "GET",
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (error) {
        // handled by useHttpClient
      }
    };
    fetchUserInfo();
  }, [sendRequest, auth.token, auth.userId])

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      onStart();
      await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/users/updateprofile",
          "POST",
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
          JSON.stringify({
            firstName: formState.inputs.firstName.value,
            lastName: formState.inputs.lastName.value,
            postalCode: formState.inputs.postalCode.value,
            city: formState.inputs.city.value,
            addrLine: formState.inputs.addrLine.value,
        })
      )
      onSuccess();
    } catch (error) {
      onError();
    }
  }


  return (
    <div>
      <ErrorModal error={error} onClear={clearError} />
        <form onSubmit={submitHandler}>
          <div className="row">
            <Input
              classes="bg-light"
              col="col-6"
              id="firstName"
              label="Vorname"
              element="input"
              type="text"
              onInput={inputHandler}
              validators={[VALIDATOR_REQUIRE()]}
            />
            <Input
              classes="bg-light"
              col="col-6"
              id="lastName"
              label="Nachname"
              element="input"
              type="text"
              onInput={inputHandler}
              validators={[VALIDATOR_REQUIRE()]}
            />
          </div>
          <div className="row">
            <Input
              classes="bg-light"
              col="col-6"
              id="postalCode"
              label="Postleitzahl"
              element="input"
              type="text"
              onInput={inputHandler}
              validators={[VALIDATOR_REQUIRE()]}
            />
            <Input
              classes="bg-light"
              col="col-6"
              id="city"
              label="Stadt"
              element="input"
              type="text"
              onInput={inputHandler}
              validators={[VALIDATOR_REQUIRE()]}
            />
          </div>
          <Input
            classes="bg-light"
            id="addrLine"
            label="Strasse, Hausnummer"
            element="input"
            type="text"
            onInput={inputHandler}
            validators={[VALIDATOR_REQUIRE()]}
          />
          <br />
          <SuccessButton width="200px" isLoading={loading} success={success}>
            Speichern
          </SuccessButton>
        </form>
    </div>
  );
}

export default AddInfo;
