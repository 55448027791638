import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const UserHeaderCard = () => {
  return (
    <Card className="mt-3">
      <Card.Header>
        <Row>
          <Col xs={2}>
            <strong>Status</strong>
          </Col>
          <Col xs={2}>
            <strong>Role</strong>
          </Col>
          <Col xs={2}>
            <strong>Name</strong>
          </Col>
          <Col>
            <strong>Email</strong>
          </Col>
          <Col >
          <div className="d-flex justify-content-start ml-3">
            <strong>Actions</strong>
          </div>
          </Col>
        </Row>
      </Card.Header>
    </Card>
  );
};

export default UserHeaderCard;
