import { useContext } from "react";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

export const useUserActions = () => {
  const auth = useContext(AuthContext);
  const {isLoading, error, sendRequest, clearError} = useHttpClient();

  const editHandler = async (inputs, id, callback) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/users/${id}`,
        "PATCH",
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        JSON.stringify({
          role: inputs.role.value,
          firstName: inputs.firstName.value,
          lastName: inputs.lastName.value,
          email: inputs.email.value,
        })
      );
      callback();
    } catch (error) {
      // handled by useHttpClient hook
    }
  }

  const deleteHandler = async (id, callback) => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${id}`,
        "DELETE",
        {
          "Content-Type": "application/json",
          authorization: "Bearer " + auth.token,
        }
      );
      callback()
    } catch (error) {
      // handled by useHttpClient hook
    }
  }

  const resendHandler = async (id, callback) => {
     try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/users/createuser",
        "POST",
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        JSON.stringify({
          uid: id
        })
      );
      callback()
    } catch (error) {
      // handled by useHttpClient hook
    }
  }

  return { editHandler, deleteHandler, resendHandler, isLoading, error, clearError };
};
