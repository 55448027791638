import React from 'react'
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import "./HeaderCard.css";

const CleaningStepHeaderDashCard = () => {
  return (
    <Card className={`p-1 m-3 border border-secondary`}>
      <Card.Body>
        <Row className="text-center">
          <Col><strong className="rsp-label">Bereich</strong></Col>
          <Col><strong className="rsp-label">Reinigung / Desinfektion</strong></Col>
          <Col><strong className="rsp-label">Reinigunsmittel</strong></Col>
          <Col xs={3}>
            <strong className="rsp-label">Fällig</strong>
          </Col>
          <Col xs={1}>
            <strong className="rsp-label rsp-hide">Action</strong>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default CleaningStepHeaderDashCard;
