import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";

import RawMaterialsCard from "./RawMaterialsCard";
import FilterBar from "../../shared/components/uiElements/FilterBar";
import RawMaterialHeaderCard from "../components/RawMaterialHeaderCard";
import Pagination from "../../shared/components/uiElements/Pagination";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useDownloader } from "../../shared/hooks/download-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { useFilter } from "../../shared/hooks/filter-hook";
import "../../css/Animation.css";

const RawMaterialList = () => {
  const auth = useContext(AuthContext);
  const [rawMaterialRes, setRawMaterialRes] = useState();
  const { sendRequest } = useHttpClient();

  // used for CSV export
  const { downloadHandler } = useDownloader(
    "/rawmaterials/csv",
    "csv",
    "wus-data"
  );

  // getting the dates for current month
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  // filter states that are set from the FilterBar
  const [term, setTerm] = useState("");
  const [status, setStatus] = useState("all");
  const [dateRange, setDateRange] = useState({ start: firstDay, end: lastDay });
  const [requestDateRange, setRequestDateRange] = useState({
    start: firstDay,
    end: lastDay,
  });

  // 3 reducers and states from the useFilter hook
  const {
    filteredResults,
    level1results,
    level2results,
    dateFilterHandler,
    filterHandler,
    queryHandler,
  } = useFilter();

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    const fetchRawMaterials = async () => {
      try {
        const res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/rawmaterials",
          "GET",
          { Authorization: "Bearer " + auth.token },
          null,
          {
            start: moment(dateRange.start).format("yyyy-MM-DD"),
            end: moment(dateRange.end).add(1, "days").format("yyyy-MM-DD"),
          }
        );
        setRawMaterialRes(res.rawMaterials);
        console.log(res.rawMaterials);
      } catch (error) {}
    };

    if (rawMaterialRes) {
      // re-runs on date range change
      // only refetch if date range is outside of last fetched date range
      if (
        dateRange.start < requestDateRange.start ||
        dateRange.end > requestDateRange.end
      ) {
        setRequestDateRange(dateRange);
        fetchRawMaterials();
      }
    } else {
      // runs fetch on page load, when rawMaterialsRes was not set before
      fetchRawMaterials();
    }
  }, [sendRequest, auth.token, dateRange]);

  const csvHandler = () => {
    let params = {
      start: moment(dateRange.start).format("yyyy-MM-DD"),
      end: moment(dateRange.end).add(1, "days").format("yyyy-MM-DD"),
    };

    if (term !== "") {
      params.query = term;
    }

    if (status) {
      params.status = status.toLocaleLowerCase();
    }

    try {
      downloadHandler(params);
    } catch (error) {}
  };

  const testCallback = (inputs, id) => {
    let updatedRMs = Object.assign([], filteredResults);
    const index = filteredResults.findIndex((element) => element.id === id);
    updatedRMs[index].testedAt = new Date();
    updatedRMs[index].approved = inputs.approved.value === "true" ? true : false;
    filterHandler("all", filteredResults);
  };

  const assignCallback = (inputs, id) => {
     let updatedRMs = Object.assign([], filteredResults);
     const index = filteredResults.findIndex((element) => element.id === id);
     if (inputs.forVet.value === "assignVet") {
       updatedRMs[index].vetId = "fakeId";
     } else {
       updatedRMs[index].vetId = null
     }
     filterHandler("all", updatedRMs);
  }

  useEffect(() => {
    queryHandler(term, level2results);
    paginate(1);
  }, [term, level2results]);
  // es lint wants to add dependencies, that would result in an infinite loop

  useEffect(() => {
    filterHandler(status, level1results);
    paginate(1);
  }, [level1results, status]);
  // es lint wants to add dependencies, that would result in an infinite loop

  useEffect(() => {
    if (rawMaterialRes !== undefined) {
      dateFilterHandler(dateRange, rawMaterialRes);
      paginate(1);
    }
  }, [rawMaterialRes, dateRange]);
  // es lint wants to add dependencies, that would result in an infinite loop

  // get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredResults.slice(indexOfFirstItem, indexOfLastItem);

  // change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // change items per page
  const changeItemsPerPage = (value) => setItemsPerPage(value);

  if (currentItems) {
    if (auth.token) {
      if (auth.userRole === "vet") {
        return (
          <div className="vh-100">
            <FilterBar
              onChange={(value) => setTerm(value)}
              onStatusFilter={(status) => setStatus(status)}
              onDateChange={(value) => setDateRange(value)}
              initialDateRange={{ start: firstDay, end: lastDay }}
              status
              date
            />

            <RawMaterialHeaderCard />

            {currentItems.map((rawMaterial) => (
              <div key={rawMaterial.id}>
                <RawMaterialsCard rm={rawMaterial} onTest={testCallback} />
              </div>
            ))}
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={filteredResults.length}
              paginate={paginate}
              changeItemsPerPage={changeItemsPerPage}
            />
          </div>
        );
      }
      return (
        <div className="vh-100">
          <FilterBar
            onChange={(value) => setTerm(value)}
            onStatusFilter={(status) => setStatus(status)}
            onDateChange={(value) => setDateRange(value)}
            initialDateRange={{ start: firstDay, end: lastDay }}
            onCsvExport={csvHandler}
            status
            date
          />

          <RawMaterialHeaderCard />

          {currentItems.map((rawMaterial) => (
            <div key={rawMaterial.id}>
              <RawMaterialsCard
                rm={rawMaterial}
                onTest={testCallback}
                assignCallback={assignCallback}
              />
            </div>
          ))}
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={filteredResults.length}
            paginate={paginate}
            changeItemsPerPage={changeItemsPerPage}
          />
        </div>
      );
    }
  }
  return (
    <div className="box">
      <div className="d-flex justify-content-center loading-screen">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    </div>
  );
};

export default RawMaterialList;
