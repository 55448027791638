import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";

import Input from "../../shared/components/formElements/Input";
import SuccessButton from "../../shared/components/formElements/SuccessButton";
import ErrorModal from "../../shared/components/uiElements/ErrorModal";
import { VALIDATOR_EMAIL } from "../../shared/util/validators";
import { useHttpClient } from "../../shared/hooks/http-hook";

import "../../css/Animation.css";

const ForgotPassword = () => {
  const { error, sendRequest, clearError } = useHttpClient();
  const [sent, setSent] = useState(false);

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/users/forgot",
        "GET",
        {
          authorization: document.getElementById("email").value,
        }
      );
     setSent(true);
    } catch (error) {
      // handled by useHttpClient hook
    }
  }

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <div className="100-vh">
        <form onSubmit={submitHandler}>
          <Input
            id="email"
            element="input"
            type="text"
            label="Email"
            onInput={() => {}}
            validators={[VALIDATOR_EMAIL()]}
            errorText="Bitte geben Sie eine gültige Email-Addresse ein."
          />

          <SuccessButton>Passwort zurücksetzen</SuccessButton>
        </form>
        <div className="mt-3">
          <Link to="/auth">
            Zurück zum Login
          </Link>
        </div>
        <CSSTransition
          in={sent}
          timeout={400}
          classNames="form-150"
          unmountOnExit
        >
          <div className="mt-3">
            <p className="text-center">
              <i
                className="far fa-envelope text-success mt-2"
                style={{ fontSize: "25px", verticalAlign: "sub" }}
              ></i>{" "}
              Eine Email zum Passwort Zurücksetzen ist unterwegs.
            </p>
          </div>
        </CSSTransition>
      </div>
    </>
  );
}

export default ForgotPassword;
