import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import VetOfficeRawMaterials from "../raw_materials/pages/VetOfficeRawMaterials";
import VetOfficeProfileSettings from "../users/pages/VetOfficeProfileSettings";
import VetOfficeTeamSettings from "../users/pages/VetOfficeTeamSettings";
import VetOfficeOrgSettings from "../users/pages/VetOfficeOrgSettings";


const VetOfficeRoutes = () => {
  return (
    <Switch>
      <Route path="/vetoffice/rawmaterials" exact component={VetOfficeRawMaterials} />
      <Route path="/vetoffice/settings/profile" exact component={VetOfficeProfileSettings} />
      <Route path="/vetoffice/settings/team" exact component={VetOfficeTeamSettings} />
      <Route path="/vetoffice/settings/organization" exact component={VetOfficeOrgSettings} />
      <Redirect to="/vetoffice/rawmaterials" />
    </Switch>
  );
}

export default VetOfficeRoutes;
