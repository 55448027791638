import React, { useEffect, useState, useContext } from "react";
import Spinner from "react-bootstrap/Spinner";

import ErrorModal from "../../shared/components/uiElements/ErrorModal";
import RoomCardCooling from "../components/roomIndex/RoomCardCooling";
import RoomsListTabs from '../components/roomIndex/RoomsListTabs';
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const RoomsListCooling = (props) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [coolRooms, setCoolRooms] = useState();

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/rooms",
          "GET",
          { Authorization: "Bearer " + auth.token }
        );
        setCoolRooms(res.rooms.filter((room) => room.cold))
      } catch (error) {}
    };
    fetchRooms();
  }, [auth.token, sendRequest]);


  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <RoomsListTabs isCooling />
      <div className="container mt-4">
        <div className="row">
          {isLoading && (
            <div className="d-flex justify-content-center loading-screen">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}
          {coolRooms &&
            coolRooms.map((room) => (
              <RoomCardCooling room={room} key={room.id} />
            ))}
        </div>
      </div>
    </>
  );
};

export default RoomsListCooling;
