import React, { useContext } from "react";

import ErrorModal from '../../shared/components/uiElements/ErrorModal';
import Button from "../../shared/components/formElements/Button";
import Input from "../../shared/components/formElements/Input";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
} from "../../shared/util/validators";

const SupplierEditForm = (props) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, clearError, sendRequest } = useHttpClient();
  const [formState, inputHandler] = useForm(
    {
      supplier_name: {
        value: props.currentSupplier.supplier_name,
        isValid: false,
      },
      addr_line: {
        value: props.currentSupplier.addr_line,
        isValid: false,
      },
      postal_code: {
        value: props.currentSupplier.postal_code,
        isValid: false,
      },
      city: {
        value: props.currentSupplier.city,
        isValid: false,
      },
      tel: {
        value: props.currentSupplier.tel,
        isValid: false,
      },
      email: {
        value: props.currentSupplier.email,
        isValid: false,
      },
      iban: {
        value: props.currentSupplier.iban,
        isValid: false,
      },
      bic: {
        value: props.currentSupplier.bic,
        isValid: false,
      },
      hunting_district: {
        value: props.currentSupplier.hunting_district,
        isValid: false,
      },
      admin_district: {
        value: props.currentSupplier.admin_district,
        isValid: false,
      },
    },
    false
  );

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/suppliers/${props.currentSupplier.id}`,
        "PATCH",
        {
          "Content-Type": "application/json",
          authorization: "Bearer " + auth.token,
        },
        JSON.stringify({
          supplier_name: formState.inputs.supplier_name.value,
          addr_line: formState.inputs.addr_line.value,
          postal_code: formState.inputs.postal_code.value,
          city: formState.inputs.city.value,
          tel: formState.inputs.tel.value,
          iban: formState.inputs.iban.value,
          bic: formState.inputs.bic.value,
          email: formState.inputs.email.value,
          hunting_district: formState.inputs.hunting_district.value,
          admin_district: formState.inputs.admin_district.value,
        })
        );
      } catch (err) {
        // handled by useHttpClient hook
      }
      props.editCallback(formState.inputs, props.currentSupplier.id);
  };
  return (
    <div>
      <ErrorModal error={error} onClear={clearError} />
      {!isLoading && props.currentSupplier && (
        <form onSubmit={formSubmitHandler}>
          <div className="row">
            <div className="col-6">
              <Input
                id="supplier_name"
                element="input"
                type="text"
                label="Name"
                initialValue={props.currentSupplier.supplier_name}
                initialValid={true}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Bitte geben Sie Vor- und Nachnamen ein."
                onInput={inputHandler}
              />
            </div>
            <div className="col-6">
              <Input
                id="addr_line"
                element="input"
                type="text"
                label="Adresse"
                initialValue={props.currentSupplier.addr_line}
                initialValid={true}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Bitte geben Sie eine Adresse ein."
                onInput={inputHandler}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <Input
                id="postal_code"
                element="input"
                type="text"
                label="PLZ"
                initialValue={props.currentSupplier.postal_code}
                initialValid={true}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Bitte geben Sie eine Postleitzahl ein."
                onInput={inputHandler}
              />
            </div>
            <div className="col-6">
              <Input
                id="city"
                element="input"
                type="text"
                label="Stadt"
                initialValue={props.currentSupplier.city}
                initialValid={true}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Bitte geben Sie eine Stadt ein."
                onInput={inputHandler}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <Input
                id="tel"
                element="input"
                type="text"
                label="Telefon"
                initialValue={props.currentSupplier.tel}
                initialValid={true}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Bitte geben Sie eine Telefonnummer ein."
                onInput={inputHandler}
              />
            </div>
            <div className="col-6">
              <Input
                id="email"
                element="input"
                type="email"
                label="Email"
                initialValue={props.currentSupplier.email}
                initialValid={true}
                validators={[VALIDATOR_EMAIL()]}
                errorText="Bitte geben Sie eine Email adresse ein."
                onInput={inputHandler}
              />
            </div>
          </div>
          <div className="row">
            <Input
              col="col-6"
              id="iban"
              element="input"
              type="text"
              label="IBAN"
              initialValue={props.currentSupplier.iban}
              initialValid={true}
              validators={[]}
              errorText="Bitte geben Sie eine gültige IBAN ein."
              onInput={inputHandler}
            />
            <Input
              col="col-6"
              id="bic"
              element="input"
              type="text"
              label="BIC"
              initialValue={props.currentSupplier.bic}
              initialValid={true}
              validators={[]}
              errorText="Bitte geben Sie eine gültige BIC ein."
              onInput={inputHandler}
            />
          </div>
          <div className="row">
            <Input
              col="col-6"
              id="admin_district"
              element="input"
              type="text"
              label="Landkreise"
              initialValue={props.currentSupplier.admin_district}
              initialValid={true}
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Bitte geben Sie einen Landkreis ein."
              onInput={inputHandler}
            />
            <Input
              col="col-6"
              id="hunting_district"
              element="input"
              type="text"
              label="Jagdbezirke"
              initialValue={props.currentSupplier.hunting_district}
              initialValid={true}
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Bitte geben Sie einen Jagdbezirk ein."
              onInput={inputHandler}
            />
          </div>
          <br/>
          <Button type="submit" disabled={!formState.isValid} primary>
            Änderung Speichern
          </Button>
        </form>
      )}
    </div>
  );
};

export default SupplierEditForm;
