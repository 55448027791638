import React from 'react'
import ReactDom from "react-dom";
import { Link } from "react-router-dom";

import "../../css/Tabs.css";

const VetOfficeSettingsTabs = props => {
  const content = (
    <div className="border-top">
      <div className="container">
        <Link to={`/vetoffice/settings/profile`}>
          <button
            className={`stealth-button tab ${
              props.isProfile ? "tab-active" : ""
            }`}
          >
            PROFIL
          </button>
        </Link>
        <Link to={`/vetoffice/settings/team`}>
          <button
            className={`stealth-button tab ${props.isTeam ? "tab-active" : ""}`}
          >
            TEAM
          </button>
        </Link>
        <Link to={`/vetoffice/settings/organization`}>
          <button
            className={`stealth-button tab ${props.isOrg ? "tab-active" : ""}`}
          >
            ORGANISATION
          </button>
        </Link>
      </div>
    </div>
  );
  return ReactDom.createPortal(content, document.getElementById("tab-hook"));
}

export default VetOfficeSettingsTabs;
