import React from "react";
import "./App.css";
// import Form from "react-bootstrap/Form";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Navigation from "./navigation/components/Navigation";

import Auth from "./users/pages/Auth";
import ForgotPassword from "./users/pages/ForgotPassword";
import ResetPassword from "./users/pages/ResetPassword";
import SetNewEmail from "./users/pages/SetNewEmail";
import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import OwnerRoutes from "./routes/OwnerRoutes";
import VetRoutes from "./routes/VetRoutes";
import VetOfficeRoutes from "./routes/VetOfficeRoutes";


const App = () => {
  const { token, login, logout, userId, userRole } = useAuth();
  let routes;

  if (token) {
    switch (userRole) {
      case 'owner':
        routes = <OwnerRoutes/>
        break;
      case 'admin':
        routes = <OwnerRoutes/>
        break;
      case 'basic':
        routes = <OwnerRoutes/>
        break;
      case 'vet':
        routes = <VetRoutes/>
        break;
      case 'vetOfficial':
        routes = <VetOfficeRoutes/>
      break;
      default:
        break;
    }
  } else {
    routes = (
      <Switch>
        <Route path="/auth" component={Auth} />
        <Route path="/forgotpassword" component={ForgotPassword} />
        <Route path="/resetpassword" component={ResetPassword} />
        <Route path="/setnewemail" component={SetNewEmail} />
        <Route component={Auth} />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userRole: userRole,
        login: login,
        logout: logout,
        userId: userId,
      }}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router>
          <Navigation />
          <div className="container mt-5">
            <main>
              {
                <AuthContext.Consumer>
                  {(value) => {
                    return (
                      routes
                    );
                  }}
                </AuthContext.Consumer>
              }
            </main>
          </div>
          <div className="background"></div>
        </Router>
      </MuiPickersUtilsProvider>
    </AuthContext.Provider>
  );
};

export default App;
