import React, { useEffect, useState, useContext } from "react";
import { CSSTransition } from "react-transition-group";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

import ErrorModal from "../../../shared/components/uiElements/ErrorModal";
import CleaningStepSettingsCard from "./CleaningStepSettingsCard";
import AddCleaningStep from "./AddCleaningStep";
import UpdateRoom from "./UpdateRoom";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";

import "../../../css/Animation.css";

const CleaningStepsList = (props) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedCleaningSteps, setLoadedCleaningSteps] = useState();
  const [roomInfo, setRoomInfo] = useState();

  const [showAdd, setShowAdd] = useState(false);
  const [showUpdateRoom, setShowUpdateRoom] = useState(false);

  useEffect(() => {
    const fetchCleaningSteps = async () => {
      try {
        const res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL +
            "/rooms/" +
            props.roomId +
            "/cleaningsteps",
          "GET",
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        setLoadedCleaningSteps(res.cleaningSteps);
        setRoomInfo({ name: res.roomName, cold: res.roomCold });
      } catch (error) {
        // handled by useHttpClient hook
      }
    };
    fetchCleaningSteps();
  }, [auth.token, props.roomId, sendRequest]);

  const addCallback = (newCleaningStep) => {
    let updatedCleaningSteps = Object.assign([], loadedCleaningSteps);
    updatedCleaningSteps.push(newCleaningStep);
    setLoadedCleaningSteps(updatedCleaningSteps);
    setShowAdd(false);
  };

  const deleteCallback = (id) => {
    let updatedCleaningSteps = Object.assign([], loadedCleaningSteps);
    const index = loadedCleaningSteps.findIndex((element) => element.id === id);
    updatedCleaningSteps.splice(index, 1);
    setLoadedCleaningSteps(updatedCleaningSteps);
  };

  const updateCallback = (inputs, id) => {
    let updatedCleaningSteps = Object.assign([], loadedCleaningSteps);
    const index = loadedCleaningSteps.findIndex((element) => element.id === id);

    updatedCleaningSteps[index].kind = inputs.kind.value;
    updatedCleaningSteps[index].repeat = inputs.repeat.value;
    updatedCleaningSteps[index].area = inputs.area.value;
    updatedCleaningSteps[index].instruction = inputs.instruction.value;
    updatedCleaningSteps[index].cleaningAgent = inputs.cleaningAgent.value;
    updatedCleaningSteps[index].applicationTime = inputs.applicationTime.value;
    updatedCleaningSteps[index].temperature = inputs.temperature.value;
    updatedCleaningSteps[index].concentration = inputs.concentration.value;

    setLoadedCleaningSteps(updatedCleaningSteps);
  };

  const updateRoomCallback = (inputs) => {
    setShowUpdateRoom(false);
    setRoomInfo({ name: inputs.name.value, cold: inputs.cold.value });
  };

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      {roomInfo && !showUpdateRoom && (
        <p className="h2 ml-3">
          {roomInfo.name}
          <button
            className="stealth-button"
            onClick={() => setShowUpdateRoom((prevMode) => !prevMode)}
          >
            <i className="fas fa-pen mx-3 text-secondary align-middle"></i>
          </button>
        </p>
      )}
      {roomInfo && showUpdateRoom && (
        <UpdateRoom
          room={roomInfo}
          onCancel={() => setShowUpdateRoom(false)}
          roomId={props.roomId}
          updateRoomCallback={updateRoomCallback}
        />
      )}
      {loadedCleaningSteps &&
        loadedCleaningSteps.map((cs, index) => (
          <CleaningStepSettingsCard
            cleaningStep={cs}
            index={index}
            key={cs.id}
            roomId={props.roomId}
            deleteCallback={deleteCallback}
            updateCallback={updateCallback}
          />
        ))}

      <CSSTransition
        in={showAdd}
        timeout={400}
        classNames="form-500"
        unmountOnExit
      >
        <div className="settings-section bg-white rounded-lg p-5 m-3">
          <p className="h5">Reinigunsschritt hinzufügen</p>
          <AddCleaningStep roomId={props.roomId} addCallback={addCallback} />
        </div>
      </CSSTransition>
      <Button
        className="m-3"
        onClick={() => setShowAdd((prevMode) => !prevMode)}
        variant="primary"
        size="lg"
      >
        +
      </Button>
    </>
  );
};

export default CleaningStepsList;
