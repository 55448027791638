import React, { useState, useEffect } from "react";
import { DateTimePicker } from "@material-ui/pickers";

import { useInput } from "../../hooks/input-hook";

const MyDateTimePicker = (props) => {
  const { inputState, changeHandler, resetHandler } = useInput(props);
  const { id, onInput } = props;
  const { value, isValid } = inputState;
  const [selectedDate, handleDateChange] = useState(value);

    useEffect(() => {
      if (props.reset) {
        if (props.resetValue) {
          resetHandler(props.resetValue, props.resetValid);
          handleDateChange(props.resetValue)
        } else {
          resetHandler(new Date(Date.now()), props.resetValid);
          handleDateChange(new Date());
        }
      }
    }, [props.reset, props.resetValid, props.resetValue, resetHandler]);

    useEffect(() => {
      onInput(id, value, isValid);
    }, [id, value, isValid, onInput]);

    return (
      <DateTimePicker className={props.classes} id={id} variant="inline" value={selectedDate} onChange={handleDateChange} onAccept={changeHandler} />
    )
}

export default MyDateTimePicker;
