import React, { useContext, useEffect, useState } from "react";

import ErrorModal from "../../shared/components/uiElements/ErrorModal";
import AddInfo from "../components/AddInfo";
import UpdateInfo from "../components/UpdateInfo";
import ChangePassword from "../components/ChangePassword";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const VetSettings = () => {
  const auth = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState();
  const { error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    const fetchUserInfo = async () => {
      let res;
      try {
        res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/users",
          "GET",
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        setUserInfo(
          res.users.filter((element) => element.id === auth.userId)[0].info
        );
      } catch (error) {
        // handled by useHttpClient
      }
    };
    fetchUserInfo();
  }, [sendRequest, auth.token, auth.userId]);

  return (
    <div>
      <ErrorModal error={error} onClear={clearError} />
      <div className="bg-white p-5 mt-3 rounded">
        <p className="h4">Profildaten ändern</p>
        {userInfo ? <UpdateInfo userInfo={userInfo} /> : <AddInfo />}
      </div>
      <div className="bg-white p-5 mt-5 rounded">
        <p className="h4">Passwort ändern</p>
        <ChangePassword />
      </div>
    </div>
  );
}

export default VetSettings;
