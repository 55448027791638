import React from "react";
import { Link } from "react-router-dom";

const RoomCardCooling = (props) => {
  return (
    <div className="col-auto mb-3 ml-0">
      <div className="card border-secondary mb-3" style={{ width: "18rem" }}>
        <div className="card-header d-flex justify-content-between">
          <div>
            <i
              className="far fa-snowflake text-primary"
              style={{ fontSize: "1rem" }}
            ></i>{" "}
            {props.room.name}
          </div>
          <Link to={`/${props.room.id}/cooling`}>
            <i className="fas fa-ellipsis-h"></i>
          </Link>
        </div>
        <div className="card-body text-secondary">
          <h5 className="card-title">Secondary card title</h5>
          <p className="card-text">
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RoomCardCooling;
