import React, { useState, useContext } from "react";

import SuccessButton from "../../shared/components/formElements/SuccessButton";
import Input from "../../shared/components/formElements/Input";
import ErrorModal from "../../shared/components/uiElements/ErrorModal";
import { useSuccess } from "../../shared/hooks/success-hook";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { VALIDATOR_MINLENGTH } from "../../shared/util/validators";
import { AuthContext } from "../../shared/context/auth-context";

const ChangePassword = () => {
  const auth = useContext(AuthContext);
  const { loading, success, onError, onSuccess, onStart } = useSuccess();
  const { error, sendRequest, clearError } = useHttpClient();
  const [notMatching, setNotMatching] = useState(null);
  const [formState, inputHandler] = useForm({
    oldPassword: {
      value: "",
      isValid: false
    },
    confirmPassword: {
      value: "",
      isValid: false,
    },
    newPassword: {
      value: "",
      isValid: false
    }
  }, false)

  const submitHandler = async (event) => {
    event.preventDefault();
    onStart();
    if (document.getElementById("newPassword").value !== document.getElementById("confirmPassword").value) {
      setNotMatching("Die Passwörter stimmen nicht überein.");
      // some error handling
    } else {
      try {
        await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/users/changepassword",
          "POST",
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
          JSON.stringify({
            password: formState.inputs.oldPassword.value,
            newPassword: formState.inputs.newPassword.value,
        })
      );
        onSuccess();
      } catch (error) {
        onError();
      }
    }
  }

  const clearNotMatching = () => {
    setNotMatching(null)
  }


  return (
    <React.Fragment>
      <ErrorModal error={ error } onClear={clearError} />
      <ErrorModal error={ notMatching } onClear={clearNotMatching} />
      <form onSubmit={submitHandler}>
        <Input
          classes="bg-light"
          id="oldPassword"
          label="Aktuelles Passwort"
          element="input"
          type="password"
          onInput={inputHandler}
          validators={[VALIDATOR_MINLENGTH(6)]}
        />
        <Input
          classes="bg-light"
          id="newPassword"
          label="Neues Passwort"
          element="input"
          type="password"
          onInput={inputHandler}
          validators={[VALIDATOR_MINLENGTH(6)]}
        />
        <Input
          classes="bg-light"
          id="confirmPassword"
          label="Passwort bestätigen"
          element="input"
          type="password"
          onInput={inputHandler}
          validators={[VALIDATOR_MINLENGTH(6)]}
        />
        <br/>
        <SuccessButton isLoading={loading} success={success} primary type="submit" disabled={!formState.isValid} width="200px" >
          Passwort bestätigen
        </SuccessButton>
      </form>
    </React.Fragment>
  );
}

export default ChangePassword;
