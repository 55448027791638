import React from "react";

const CustomerNew = () => {
  return (
    <div>
      <h1>Customer New</h1>
    </div>
  );
};

export default CustomerNew;
