import React from 'react';

import MyDateTimePicker from '../formElements/DateTimePicker'
import SuccessButton from '../formElements/SuccessButton'
import { useForm } from "../../hooks/form-hook";

const RangeFilter = props => {
  const [formState, inputHandler] = useForm(
    {
      start: {
        value: props.dateRange.start,
        isValid: true,
      },
      stop: {
        value: props.dateRange.stop,
        isValid: true,
      },
    },
    false
  );


  const submitHandler = (event) => {
    event.preventDefault();
    props.dateChangeCallback(formState.inputs);
  }


  return (
    <form onSubmit={submitHandler}>
      <div className="row px-3">
        <MyDateTimePicker
          classes="bg-light px-2 pb-1 mr-3 col-4 rounded border d-flex"
          id="start"
          onInput={inputHandler}
          validators={[]}
          initialValue={
            props.dateRange.start
          }
          initialValid={true}
        />
        <MyDateTimePicker
          classes="bg-light px-2 pb-1 mr-3 col-4 rounded border d-flex"
          id="stop"
          onInput={inputHandler}
          validators={[]}
          initialValue={
            props.dateRange.stop
          }
          initialValid={true}
        />
        <SuccessButton width="150px">update</SuccessButton>
      </div>
    </form>
  );
}

export default RangeFilter;
