import React, { useEffect, useContext, useState } from 'react'
import Spinner from 'react-bootstrap/Spinner'

import VetOfficeSettingsTabs from '../components/VetOfficeSettingsTabs';
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import ChangeOrgInfo from '../components/ChangeOrgInfo';

const VetOfficeOrgSettings = () => {
  const auth = useContext(AuthContext);
  const { isLoading, sendRequest, error, clearError } = useHttpClient();
  const [company, setCompany] = useState();

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/companies/company",
          "GET",
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        setCompany(res.company);
      } catch (error) {
        // handled by useHttpClient
      }
    }
    fetchCompany();
  }, [sendRequest, auth.token])

  return (
    <>
      <VetOfficeSettingsTabs isOrg />
      {isLoading && (
        <div className="text-center">
          <Spinner animation="border" />
        </div>
      )}
      {company &&
        <ChangeOrgInfo company={company} />
      }
    </>
  );
}

export default VetOfficeOrgSettings;
