import React, { useCallback, useContext } from "react";

import MyDatePicker from "../formElements/DatePicker";
import Input from "../formElements/Input";
import SuccessButton from "../formElements/SuccessButton";
import { useForm } from "../../hooks/form-hook";
import { AuthContext } from "../../context/auth-context";

const FilterBar = props => {
  const auth = useContext(AuthContext);
  const [formState, inputHandler] = useForm(
    {
      query: {
        value: "",
        isValid: true,
      },
      start: {
        value: props.initialDateRange.start,
        isValid: true,
      },
      end: {
        value: props.initialDateRange.end,
        isValid: true,
      }
    },
    true
  );

  const inputCallback = useCallback(
    (id, value, isValid) => {
      inputHandler(id, value, isValid);
      props.onChange(value);
    },
    [inputHandler, props.onChange]
  );

  const dateInputCallback = (id, value) => {
    const formData = {
      start: formState.inputs.start.value,
      end: formState.inputs.end.value
    }
    const result = {...formData, [id]: value}
    props.onDateChange(result);
  }

  return (
    <div className="card mb-3 p-3">
      <h4 className="ml-3">Filter</h4>
      <form id="searchFilter">
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            <div className="px-3 mt-1">
              <Input
                id="query"
                placeholder="Suche..."
                element="input"
                type="text"
                onInput={inputCallback}
                validators={[]}
                initialValid={true}
              />
            </div>
            {props.date && (
              <React.Fragment>
                <div className="px-3">
                  <MyDatePicker
                    id="start"
                    label="Start"
                    onInput={inputHandler}
                    onAccept={dateInputCallback}
                    validators={[]}
                    initialValue={props.initialDateRange.start}
                    initialValid={true}
                  />
                </div>
                <div className="px-3">
                  <MyDatePicker
                    id="end"
                    label="Ende"
                    onInput={inputHandler}
                    onAccept={dateInputCallback}
                    validators={[]}
                    initialValue={props.initialDateRange.end}
                    initialValid={true}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </form>
      <div className="d-flex justify-content-between">
        {props.status && (
          <div className="d-flex justify-content-start mb-3 mt-3 px-3">
            {auth.userRole !== "vetOfficial" &&
            <div
              className="btn shadow btn-secondary btn-sm mr-3"
              onClick={() => props.onStatusFilter("pending")}
            >
              Ausstehen
            </div>}
            <div
              className="btn shadow btn-success btn-sm mr-3"
              onClick={() => props.onStatusFilter("approved")}
            >
              Freigegeben
            </div>
            <div
              className="btn shadow btn-danger btn-sm mr-3"
              onClick={() => props.onStatusFilter("denied")}
            >
              Abgelehnt
            </div>
            <div
              className="btn shadow btn-primary btn-sm mr-3"
              onClick={() => props.onStatusFilter("all")}
            >
              Alle
            </div>
          </div>
        )}
        {props.onCsvExport && (
          <SuccessButton
            outline
            width="150px"
            height="40px"
            classes="mt-3"
            onClick={props.onCsvExport}
          >
            CSV Export
          </SuccessButton>
        )}
      </div>
      {auth.userRole !== "vetOfficial" && auth.userRole !== "vet" && (
        <div className="d-flex justify-content-start mb-3 mt-2 px-3">
          <div
            className="btn shadow btn-outline-info btn-sm mr-3"
            onClick={() => props.onStatusFilter("notspecified")}
          >
            Nicht zugewiesen
          </div>
          <div
            className="btn shadow btn-info btn-sm mr-3"
            onClick={() => props.onStatusFilter("novet")}
          >
            Kein Veterinär
          </div>
        </div>
      )}
    </div>
  );
}

export default FilterBar;
