import React, { useEffect } from "react";
import queryString from "query-string";
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

import ErrorModal from '../../shared/components/uiElements/ErrorModal';
import { useHttpClient } from "../../shared/hooks/http-hook";

const SetNewEmail = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { resetToken } = queryString.parse(props.location.search);

  useEffect(() => {
    const setMail = async () => {
      try {
        await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/users/setnewemail",
          "POST",
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + resetToken,
          }
        );
      } catch (error) {
        // handled by useHttpClient hook
      }
    }
    setMail()
  }, [])

  return (
    <>
    <ErrorModal error={error} onClear={clearError} />
    {isLoading &&
      <div className="text-center">
        <Spinner animation="border"/>
      </div>
    }
    {!isLoading &&
      <Alert variant="success" className="h5">
        <div className="d-flex justify-content-between">
          Ihre neue Emailadresse ist jetzt aktiv!{" "}
          <Alert.Link href="/auth">Zum Login</Alert.Link>
        </div>
      </Alert>
    }
    </>
  );
};

export default SetNewEmail;
