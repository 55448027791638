import { useState, useContext, useCallback } from "react";
import axios from  "axios";
import { AuthContext } from "../context/auth-context";

export const useDownloader = (relUrl, fileType, genericFileName = null) => {
         const auth = useContext(AuthContext);
         const [isLoading, setIsLoading] = useState(false);
         const [error, setError] = useState(null);

         const downloadHandler = useCallback(async (params, fileName = null) => {
           setIsLoading(true);

           let paramString = "?";
           if (params) {
             Object.entries(params).forEach((entry) => {
               const [key, value] = entry;
               paramString += key + "=" + value + "&";
             });
           }

           axios({
             url: process.env.REACT_APP_BACKEND_URL + relUrl + paramString,
             method: "GET",
             responseType: "blob",
             headers: {
               Authorization: "Bearer " + auth.token,
             },
           })
             .then((response) => {
               setIsLoading(false);
               const url = window.URL.createObjectURL(
                 new Blob([response.data])
               );
               const link = document.createElement("a");
               link.href = url;
               link.setAttribute("download", `${genericFileName || fileName}.${fileType}`);
               document.body.appendChild(link);
               link.click();
             })
             .catch((err) => {
               setError(err.response.statusText);
               setIsLoading(false);
               Promise.reject(err.response);
             });
         }, [auth.token, fileType, genericFileName, relUrl]);

         const clearError = () => {
           setError(null);
         };

         return { isLoading, error, downloadHandler, clearError };
       };
