import React, { useEffect, useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";

import RawMaterialsTabs from '../components/RawMaterialsTabs';
import DefectsChecks from '../components/DefectsChecks';
import Input from "../../shared/components/formElements/Input";
import Button from "../../shared/components/formElements/Button";
import SuccessButton from "../../shared/components/formElements/SuccessButton";
import Select from "../../shared/components/formElements/Select";
import Checkbox from "../../shared/components/formElements/Checkbox";
import MyDateTimePicker from "../../shared/components/formElements/DateTimePicker";
import Spinner from "react-bootstrap/Spinner";
import ErrorModal from "../../shared/components/uiElements/ErrorModal";
import SignaturePad from "../../shared/components/formElements/SignaturePad";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useSuccess } from "../../shared/hooks/success-hook";
import { VALIDATOR_REQUIRE } from "../../shared/util/validators";
import { AuthContext } from "../../shared/context/auth-context";

const RawMaterialsNew = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [loadedSuppliers, setLoadedSuppliers] = useState();
  const [supplierOptions, setSupplierOptions] = useState();
  const [huntingDistricts, setHuntingDistricts] = useState([]);
  const [adminDistricts, setAdminDistricts] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [isSigning, setIsSigning] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const { loading, success, onStart, onSuccess, onError } = useSuccess();

  const [defects, setDefects] = useState();

  const defectsCallback = (selectedDefects) => {
    setDefects(selectedDefects);
  }


  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const res = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/suppliers",
          "GET",
          { Authorization: "Bearer " + auth.token }
        );

        const options = res.suppliers.map((supplier) => ({
          key: supplier.supplier_name,
          value: supplier.id,
        }));

        // sets the hunting districts to all options
        let totalDistricts = res.suppliers
          .map((supplier) => supplier.hunting_district.split(","))
          .flat();

        // sets the admin districts to all options
        let totalAdminDistricts = res.suppliers
          .map((supplier) => supplier.admin_district.split(","))
          .flat();

        // drop dublicate values from hunting districts
        let set = new Set(totalDistricts);
        totalDistricts = Array.from(set);

        // drop dublicate values from admin districts
        let adminSet = new Set(totalAdminDistricts);
        totalAdminDistricts = Array.from(adminSet);

        setHuntingDistricts(
          totalDistricts.map((district) => ({ key: district, value: district }))
        );

        setAdminDistricts(
          totalAdminDistricts.map((district) => ({ key: district, value: district }))
        );

        // creates a loaded suppliers object with IDs as keys
        setLoadedSuppliers(
          res.suppliers.reduce((obj, item) => {
            obj[item.id] = item;
            return obj;
          }, {})
        );

        setSupplierOptions(options);
      } catch (error) {
        // handled by useHttpClient hook
      }
    };
    fetchSuppliers();
  }, [sendRequest, auth.token, isSigned]);

  const initFormState = {
    killedAt: {
      value: "",
      isValid: false,
    },
    type: {
      value: "",
      isValid: false,
    },
    gender: {
      value: "",
      isValid: false,
    },
    weight: {
      value: "",
      isValid: false,
    },
    age: {
      value: "",
      isValid: false,
    },
    supplier: {
      value: "",
      isValid: false,
    },
    hunting_district: {
      value: "",
      isValid: false,
    },
    admin_district: {
      value: "",
      isValid: false,
    },
    comment: {
      value: "",
      isValid: true,
    },
    behavioralDisorder: {
      value: true,
      isValid: true,
    },
    distinctFeatures: {
      value: true,
      isValid: true,
    },
    contamination: {
      value: true,
      isValid: true,
    },
    isOriginal: {
      value: true,
      isValid: true,
    },
    hunterSignature: {
      value: "",
      isValid: false,
    },
    labelNumber: {
      value: "",
      isValid: false,
    },
  };
  const [formState, inputHandler] = useForm(
    initFormState,
    false
  );


  useEffect(() => {
    if (formState.inputs.hunterSignature.isValid) {
      setIsSigned(true);
    }
  }, [formState.inputs.hunterSignature])


  const formSubmitHandler = async (event) => {
    event.preventDefault();
    console.log(formState.inputs)
    try {
      onStart();
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/rawmaterials",
        "POST",
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token
        },
        JSON.stringify({
          killedAt: formState.inputs.killedAt.value,
          type: formState.inputs.type.value,
          gender: formState.inputs.gender.value,
          weight: formState.inputs.weight.value,
          defects: defects,
          age: formState.inputs.age.value,
          supplier: formState.inputs.supplier.value,
          hunting_district: formState.inputs.hunting_district.value,
          admin_district: formState.inputs.admin_district.value,
          comment: formState.inputs.comment.value,
          behavioralDisorder: formState.inputs.behavioralDisorder.value,
          distinctFeatures: formState.inputs.distinctFeatures.value,
          contamination: formState.inputs.contamination.value,
          labelNumber: formState.inputs.labelNumber.value,
          hunterSignature: formState.inputs.hunterSignature.value,
          isOriginal: formState.inputs.isOriginal.value
        })
      );
      onSuccess();
      setIsSigned(false);
      history.push("/raw_materials/rawmaterials");
    } catch (error) {
      onError();
      // handled by useHttpClient hook
    }
  };

  const selectHandler = (event) => {
    if (event.target.value !== "default") {
      setHuntingDistricts(loadedSuppliers[event.target.value].hunting_district.split(",").map((district) => ({
        key: district,
        value: district
      })))
       setAdminDistricts(
         loadedSuppliers[event.target.value].admin_district
           .split(",")
           .map((district) => ({
             key: district,
             value: district,
           }))
       );
    }
  }

  const modalOpenHandler = () => {
    setIsSigning(true);
  }

  const modalCloseHandler = () => {
    setIsSigning(false);
  }

    return (
      <React.Fragment>
        <RawMaterialsTabs isCreate />
        <ErrorModal error={error} onClear={clearError} />
        {isLoading && (
          <div className="box">
            <div className="d-flex justify-content-center loading-screen">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          </div>
        )}
        <p className="h2">Lieferung anlegen</p>
        <hr />
        <div className="py-5 w-100">
          {supplierOptions && (
            <form action="" onSubmit={formSubmitHandler}>
              <div className="row">
                <div className="col-4">
                  <label className="my-2 form-label">
                    Lieferant{" "}
                    <Link
                      to="/supplier/suppliernew?origin=rawmaterial"
                      className="mt-n1 p-0"
                    >
                      <small className="mt-n1 p-0">(neu anlegen)</small>
                    </Link>
                  </label>
                  <Select
                    reset={success}
                    id="supplier"
                    onInput={inputHandler}
                    options={supplierOptions}
                    onSelect={selectHandler}
                    validators={[VALIDATOR_REQUIRE()]}
                  />
                </div>
                <Select
                  reset={success}
                  col="col-4 mb-0"
                  id="hunting_district"
                  label="Revier"
                  onInput={inputHandler}
                  options={huntingDistricts}
                  validators={[VALIDATOR_REQUIRE()]}
                />
                <Select
                  reset={success}
                  col="col-4 mb-0"
                  id="admin_district"
                  label="Landkreis"
                  onInput={inputHandler}
                  options={adminDistricts}
                  validators={[VALIDATOR_REQUIRE()]}
                />
              </div>
              <br />
              <div className="row">
                <div className="col-4">
                  <label className="form-label my-2 d-block">
                    Erlegungsdatum/-uhrzeit
                  </label>
                  <MyDateTimePicker
                    classes="bg-white px-2 pb-1 rounded border d-flex"
                    id="killedAt"
                    onInput={inputHandler}
                    validators={[]}
                    reset={success}
                    resetValid={false}
                    initialValue={new Date(Date.now())}
                  />
                </div>
                <Input
                  reset={success}
                  col="col-4"
                  id="labelNumber"
                  element="input"
                  type="text"
                  label="Wildmarkennummer"
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText="Bitte geben Sie die Wildmarkennummer ein."
                  onInput={inputHandler}
                />
                <Select
                  reset={success}
                  col="col-4"
                  id="type"
                  label="Wildart"
                  onInput={inputHandler}
                  options={[
                    { key: "Reh", value: "Reh" },
                    { key: "Rothirsch", value: "Rothirsch" },
                    { key: "Damhirsch", value: "Damhirsch" },
                    { key: "Wildschwein", value: "Wildschwein" },
                    { key: "Mufflon", value: "Mufflon" },
                  ]}
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText="Bitte wählen Sie eine Wildart aus."
                />
              </div>
              <br />
              <div className="row">
                <Select
                  reset={success}
                  col="col-4"
                  id="gender"
                  label="Geschlecht"
                  onInput={inputHandler}
                  options={[
                    {
                      key: "Weiblich",
                      value: "f",
                    },
                    {
                      key: "Männlich",
                      value: "m",
                    },
                  ]}
                  validators={[]}
                />
                <Input
                  reset={success}
                  col="col-4"
                  id="weight"
                  element="input"
                  type="number"
                  step="0.1"
                  label="Gewicht"
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText="Bitte geben Sie ein Gewicht ein."
                  onInput={inputHandler}
                />
                <Select
                  reset={success}
                  col="col-4"
                  id="age"
                  label="Altersklasse"
                  options={[
                    {key: "Altersklasse 0", value: 0},
                    {key: "Altersklasse 1", value: 1},
                    {key: "Altersklasse 2", value: 2},
                    {key: "Altersklasse 3", value: 3},
                    {key: "Altersklasse 4", value: 4},
                  ]}
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText="Bitte geben Sie eine Altersklasse ein."
                  onInput={inputHandler}
                />
              </div>
              {/* not resetting yet */}
              <label className="my-3 form-label">Defekte</label>
              <DefectsChecks defectsCallback={defectsCallback} />
              <br />
              <Input
                reset={success}
                resetValid={true}
                id="comment"
                element="textarea"
                label="Bemerkung (z.B. wenn Todesursache nicht Erlegung war order eine Nachsuche nötig wurde)"
                onInput={inputHandler}
                validators={[]}
                initialValid={true}
              />
              <br />
              <Checkbox
                inverse
                type="checkbox"
                id="behavioralDisorder"
                label={`Vor dem Erlegen wurden keine Verhaltensstörungen des Tieres beobachtet.`}
                onInput={inputHandler}
                reset={success}
                resetValue={true}
                resetValid={true}
                initialValid={true}
                initialValue={true}
                validators={[]}
              />
              <Checkbox
                inverse
                type="checkbox"
                id="distinctFeatures"
                label={`Bei der Untersuchung des Tieres wurden keine Merkmale beobachtet, die das Fleisch
                        als bedenklich zum Verzehr durch Menschen erscheinen lassen.`}
                onInput={inputHandler}
                reset={success}
                resetValue={true}
                resetValid={true}
                initialValid={true}
                initialValue={true}
                validators={[]}
              />
              <Checkbox
                inverse
                reset={success}
                resetValue={true}
                resetValid={true}
                type="checkbox"
                id="contamination"
                label={`Ein Verdacht auf Umweltkontamation liegt nicht vor.`}
                onInput={inputHandler}
                initialValid={true}
                initialValue={true}
                validators={[]}
              />
              <br />
              <Checkbox
                inverse
                reset={success}
                resetValue={true}
                resetValid={true}
                type="checkbox"
                id="isOriginal"
                label="Originaldokument liegt vor."
                onInput={inputHandler}
                initialValid={true}
                initialValue={true}
                validators={[]}
              />
              <br/>
              {isSigned && (
                // <Button type="submit" primary disabled={!formState.isValid}>
                //   Dokument erstellen
                // </Button>
                <SuccessButton
                  success={success}
                  isLoading={loading}
                  disabled={!formState.isValid}
                >
                  Dokument erstellen
                </SuccessButton>
              )}
            </form>
          )}
          {!isSigned && (
            <Button onClick={modalOpenHandler} primary>
              Dokument unterschreiben
            </Button>
          )}
        </div>
        <SignaturePad
          id="hunterSignature"
          show={isSigning}
          onClear={modalCloseHandler}
          onInput={inputHandler}
        />
      </React.Fragment>
    );
};

export default RawMaterialsNew;
