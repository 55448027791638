import React from "react";

const Customers = () => {
  return (
    <div>
      <h1>Customers</h1>
    </div>
  );
};

export default Customers;
