import React, { useContext } from "react";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

import SupplierTabs from '../components/SupplierTabs'
import SupplierForm from "../../suppliers/components/SupplierForm";
import ErrorModal from "../../shared/components/uiElements/ErrorModal";
import LoadingSpinner from "../../shared/components/uiElements/LoadingSpinner";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";


const NewSupplier = (props) => {
  const auth = useContext(AuthContext);
  const params = queryString.parse(props.location.search);
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [formState, inputHandler] = useForm(
    {
      supplier_name: {
        value: "",
        isValid: false,
      },
      addr_line: {
        value: "",
        isValid: false,
      },
      postal_code: {
        value: "",
        isValid: false,
      },
      city: {
        value: "",
        isValid: false,
      },
      tel: {
        value: "",
        isValid: true,
      },
      email: {
        value: "",
        isValid: true,
      },
      iban: {
        value: "",
        isValid: true,
      },
      bic: {
        value: "",
        isValid: true,
      },
      hunting_district: {
        value: "",
        isValid: false,
      },
      admin_district: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/suppliers",
        "POST",
        {
          "Content-Type": "application/json",
          authorization: "Bearer " + auth.token
        },
        JSON.stringify({
          supplier_name: formState.inputs.supplier_name.value,
          addr_line: formState.inputs.addr_line.value,
          postal_code: formState.inputs.postal_code.value,
          city: formState.inputs.city.value,
          tel: formState.inputs.tel.value,
          iban: formState.inputs.iban.value,
          bic: formState.inputs.bic.value,
          email: formState.inputs.email.value,
          hunting_district: formState.inputs.hunting_district.value,
          admin_district: formState.inputs.admin_district.value,
        })
      );
      history.push("/raw_materials/rawmaterialsnew");
    } catch (err) {
      // handled by useHttpClient hook
    }
  };

  return (
    <React.Fragment>
      <SupplierTabs isCreate origin={params.origin} />
      <ErrorModal error={error} onClear={clearError} />
      <div className="">
        <p className="h2">Lieferanten anlegen</p>
        <hr />
        <br />
        <SupplierForm
          formSubmitHandler={formSubmitHandler}
          inputHandler={inputHandler}
          formState={formState}
        />
        {isLoading && <LoadingSpinner asOverlay />}
      </div>
    </React.Fragment>
  );
};

export default NewSupplier;
