import React from "react";

import SettingsTabs from "../components/SettingsTabs";
import UserList from "../components/UserList";

const TeamSettings = () => {

  return (
    <div>
      <SettingsTabs isTeam />
      <p className="h4">Team verwalten</p>
      <UserList />
      <br/>
    </div>
  );
};

export default TeamSettings;
